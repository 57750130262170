import { IDataRoomFile, IDataRoomRootFolder } from '../../stores/types';

import { useCallback, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

import {
    DataRoomRootFolderState,
} from '../../stores/states';
import { useBreadCrumb } from '../../stores';

import './bread-crumb.scss';

export const BreadCrumb = () => {

	// Setter function to update the root folders in the data room
	const setDataRoomRootFolders = useSetRecoilState(DataRoomRootFolderState);

	const { sliceBreadCrumb,  breadCrumbFolders } = useBreadCrumb(); 

	// Handles the click on a breadcrumb folder
	// Updates the breadcrumb folders and root folders based on the selected index
	const handleOnClick = useCallback(
		(index: number) => {
			sliceBreadCrumb(index);
			// Get the selected folder based on the clicked index
			const selectedFolder = breadCrumbFolders[index];
			// If the selected folder has combineData, update the root folders state
			if (selectedFolder?.combineData) {
				setDataRoomRootFolders(
					selectedFolder.combineData as (IDataRoomRootFolder & IDataRoomFile)[]
				);
			}
		},
		[breadCrumbFolders, setDataRoomRootFolders] // Re-run callback when these values change
	);

	// Memoized rendering of breadcrumb folders, ensuring efficient rendering
	// Only re-renders when breadCrumbFolders change
	const breadcrumb = useMemo(() => {
		// Check if there are more than 1 breadcrumb folder to render
		return breadCrumbFolders?.length > 1
			? // Map through the breadcrumb folders to create individual folder items
				breadCrumbFolders.map(({ name }, index) => (
					<div
						key={`breadcrumb-${index}`} // Unique key for each breadcrumb item
						className="Bread__Crumb--folder"
						onClick={() => handleOnClick(index)} // Set the click handler for the folder
					>
						{/* Show arrow separator if not the first item */}
						{index > 0 && <i className="ri-arrow-right-s-line" />}
						{/* Display the folder name */}
						<div className="Bread__Crumb--folder_name">{name}</div>
					</div>
				))
			: null; // Return null if no breadcrumb folders exist or only 1 folder exists
	}, [breadCrumbFolders, handleOnClick]); // Re-run rendering when breadCrumbFolders or handleOnClick changes

	// Render the breadcrumb container with the generated breadcrumb items
	return <div className="Bread__Crumb">{breadcrumb}</div>;
};
