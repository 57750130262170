export const removeEmptyValues = (obj: any): any => {
	// Create a new object, and iterate over the keys of the original object
	return Object.keys(obj).reduce((acc: any, key: string) => {
	  const value = obj[key];
  
	  // Check if the value is not an empty string, null, or an empty object
	  if (value !== '' && value !== null && (typeof value !== 'object' || Object.keys(value).length > 0)) {
		// If the value is not empty, null, or an empty object, add it to the accumulator object
		acc[key] = value;
	  } else if (typeof value === 'object' && value !== null) {
		// If the value is an object (but not null), recursively clean it
		acc[key] = removeEmptyValues(value);
	  }
	  return acc;
	}, {});
  };