/**
 * Utility function to format a date into MM/DD/YYYY
 * @param date - A Date object or a valid date string/timestamp
 * @returns The formatted date string in MM/DD/YYYY format
 */
export const formatDate = (date: Date | string | number): string => {
    const parsedDate = new Date(date);
  
    if (isNaN(parsedDate.getTime())) {
      return 'NA'
    }
  
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const year = parsedDate.getFullYear();
  
    return `${month}/${day}/${year}`;
  };

/**
 * Converts a size value (in bytes) to a human-readable format (e.g., KB, MB, GB).
 * If the input is a string, it is returned as-is.
 *
 * @param size - The size value as a string or number (bytes).
 * @returns A formatted size string (e.g., "10 MB", "1.5 GB").
 */
export const formatSizeUnits = (size: string | number): string => {
	// Return the input directly if it's a string
	if (typeof size === 'string') {
		return size;
	}

	// Check size and convert to appropriate units
	if (size >= 1073741824) {
		// Convert bytes to GB with two decimal places
		return (size / 1073741824).toFixed(2) + ' GB';
	} else if (size >= 1048576) {
		// Convert bytes to MB with two decimal places
		return (size / 1048576).toFixed(2) + ' MB';
	} else if (size >= 1024) {
		// Convert bytes to KB with two decimal places
		return (size / 1024).toFixed(2) + ' KB';
	} else {
		// Handle very small sizes and default to MB
		return size.toFixed(2) + ' MB';
	}
};
