import { useCallback, useState } from 'react';
import { FormSection } from '../../../company-registration-form';
import { AdministratorInformationFormDetails } from './constant';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
	IInviteAdministratorState,
	InviteAdministratorState,
	ISelectOption,
} from '../../store';
import { userRoleState } from '../../../../hooks';

import './invite-adminisitrator.scss';
import { isAlphaWithSpaces, isValidEmail, isValidStringWithCommas } from '../../../../utils';

export const InviteAdminisitrator = ({
	onPhoneValidationChange,
}: {
	onPhoneValidationChange: (isValid: boolean) => void;
}) => {
	const [inviteAdministrator, setIinviteAdministrator] = useRecoilState(
		InviteAdministratorState
	);
	const [errors, setErrors] = useState({});
	const roles = useRecoilValue(userRoleState);
	// Map roles to dropdown options
	const mappedroles = roles?.map((role) => ({
		label: role.name,
		value: role.id,
	}));

	const handleChangeSelect = useCallback(
		(selectedOption: ISelectOption, name: string) => {
			// Check if the selected option's label matches any role
			const matchedRole = mappedroles.find(
				(role) => role.label === selectedOption.value
			);
			
			// Create a processed option with the matched value
			const processedOption = {
				...selectedOption,
				value: matchedRole?.value ?? '', // Use matched value or fallback
			};
	
			// Update state with the processed option
			setIinviteAdministrator((prevData) => ({
				...prevData,
				[name]: [processedOption], // Store as an array
			}));
	
			// Additional logic for permissions and validation...
		},
		[mappedroles, setIinviteAdministrator]
	);

	const handleSelectCountryCode = useCallback((countryCode: string) => {
		setIinviteAdministrator((prevState) => ({
			...prevState,
			countryCode: countryCode,
		}));
	}, []);

	const handleCountryChange = useCallback(
		(phoneData: { countryCode: string | number; phone: string }) => {
			setIinviteAdministrator((prevState) => ({
				...prevState,
				countryCode: phoneData.countryCode,
				phoneNumber: phoneData.phone,
			}));
		},
		[]
	);

	const handleValidation = (isValid: boolean) => {
		onPhoneValidationChange(isValid);
	};

	// Handle text input change
	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = e.target;
			const inputValue = value.trim();

			// Block updating state if the value is numeric for specific fields
			if (
				(name === 'firstName' || name === 'lastName')
			) {
				if (!isAlphaWithSpaces(inputValue) || inputValue.length > 50) {
					return;
				}
			}
			if(name === 'titles'){
				if(!isValidStringWithCommas(inputValue) || inputValue.length > 50){
					return;
				}
			}

			setIinviteAdministrator((prevData) => ({
				...prevData,
				[name as keyof IInviteAdministratorState]: value,
			}));

			if (name === 'email' && inputValue.length > 0) {
				if (!isValidEmail(value)) {
					setErrors((prevErrors) => ({
						...prevErrors,
						[name]: `Invaid email address`,
					}));
					return;
				}
			}

			// Validation for required fields
			const field = AdministratorInformationFormDetails.flatMap(
				(section) => section.fields
			).find((field) => field.name === name);

			if (field?.isRequired && !value.trim()) {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: `${field.label} is required`,
				}));
			} else {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: '',
				}));
			}
		},
		[setIinviteAdministrator, setErrors, isValidEmail,isAlphaWithSpaces,isValidStringWithCommas] // Dependencies for useCallback
	);

	// Prefill dropdown options based on the existing inviteAdministrator state
	const prefillOptions = (
		options: ISelectOption[],
		name: keyof IInviteAdministratorState
	) => {
		if (!inviteAdministrator[name]) return options;
		// Check if the value is an array before mapping
		const selectedOptions = inviteAdministrator[name];
		if (Array.isArray(selectedOptions)) {
			return selectedOptions.map((selectedOption: ISelectOption) => {
				const matchedRole = mappedroles.find(
					(role) => role.label === selectedOption.label
				);
				return {
					...selectedOption,
					value: matchedRole?.value || selectedOption.value,
				};
			});
		}

		// If it's not an array, return the original options
		return options;
	};

	const getCustomOptions = useCallback(
		({
			data,
			innerRef,
			innerProps,
		}: {
			data: ISelectOption;
			innerRef: React.Ref<HTMLDivElement>;
			innerProps: any;
		}) => {
			return (
				<div
					className="InviteAdminisitrator__dropdown-option"
					ref={innerRef}
					{...innerProps}
				>
					<div className="InviteAdminisitrator__dropdown-option--header">
						<div className="InviteAdminisitrator__dropdown-option--text">
							<div className="InviteAdminisitrator__dropdown-option--label">
								{data.label}
							</div>
							<div className="InviteAdminisitrator__dropdown-option--description">
								{data.description}
							</div>
						</div>
					</div>
				</div>
			);
		},
		[]
	);

	return (
		<div className="InviteAdminisitrator">
			{AdministratorInformationFormDetails.map((section, index) => (
				<FormSection
					key={index}
					title={section.title}
					body={section.fields.map((field) => field)}
					handleChange={handleChange}
					formData={inviteAdministrator}
					errors={errors}
					handleChangeSelect={handleChangeSelect}
					getCustomOptions={getCustomOptions}
					prefillOptions={prefillOptions}
					handleSelectCountryCode={handleSelectCountryCode}
					handleCountryChange={handleCountryChange}
					defaultCountryCode={inviteAdministrator.countryCode}
					defaultNumber={inviteAdministrator.phoneNumber}
					handleValidation={handleValidation}
				/>
			))}
		</div>
	);
};
