import { useCallback, useState } from 'react';
import './stakeholder-form-details.scss';
import { FormSection } from '../../../company-registration-form';
import { StakeholderFormDetailsData, StakeholderFormState } from '../../store';
import { useRecoilState } from 'recoil';
import { ISelectOption } from '../../../invite-administrator-modal';
import {
	containsDigit,
	isAlphanumeric,
	isAlphaWithSpaces,
	isValidEmail,
	isValidZip,
} from '../../../../utils';
export const StakeholderFormDetails = ({
	onPhoneValidationChange,
}: {
	onPhoneValidationChange: (isValid: boolean) => void;
}) => {
	const [stakeholderFormData, setStakeholderFormData] =
		useRecoilState(StakeholderFormState);
	const [errors, setErrors] = useState({});

	const handleValidation = (isValid: boolean) => {
		onPhoneValidationChange(isValid);
	};

	const handleChangeSelect = useCallback(
		(selectedOption: ISelectOption, name: string) => {
			setStakeholderFormData((prevData) => ({
				...prevData,
				[name]: [{ ...selectedOption }],
			}));
		},
		[]
	);

	const handleSelectCountryCode = useCallback((countryCode: string) => {
		setStakeholderFormData((prevState) => ({
			...prevState,
			countryCode: countryCode,
		}));
	}, []);

	const handleCountryChange = useCallback(
		(phoneData: { countryCode: string | number; phone: string }) => {
			setStakeholderFormData((prevState) => ({
				...prevState,
				countryCode: phoneData.countryCode,
				phoneNumber: phoneData.phone,
			}));
		},
		[]
	);

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = e.target;
			const inputValue = value.trim();
			const ValidateField = ['city', 'state'];
			// Block updating state if the value is numeric for specific fields
			if (
				ValidateField.includes(name) &&
				containsDigit(inputValue) &&
				inputValue.length > 0
			) {
				return; // Exit early if the value is numeric
			}

			if (
				(name === 'firstName' || name === 'lastName' || name === 'state' || name === 'city')
			) {
				if (!isAlphaWithSpaces(inputValue) || inputValue.length > 50) {
					return;
				}
			}
			// Validate 'taxId' field for numeric and length checks
			if (name === 'taxId') {
				if (!isAlphanumeric(inputValue) || inputValue.length > 10) {
					return;
				}
			}

			if (name === 'postalCode') {
				if (!isValidZip(inputValue) || inputValue.length > 10) {
					return;
				}
			}
			if(name === 'institutionName'){
				if (!isAlphanumeric(inputValue) || inputValue.length > 50) {
					return;
				}
			}

			setStakeholderFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));

			if (name === 'postalCode') {
				if (inputValue.length < 4) {
					// Set an error if the length is not between 4 and 10
					setErrors((prevErrors) => ({
						...prevErrors,
						[name]: 'The ZIP code must be between 4 and 10 characters long.',
					}));
					return; // Exit early if invalid
				} else {
					// Clear the error if input is valid
					setErrors((prevErrors) => ({
						...prevErrors,
						[name]: '',
					}));
				}
			}
			if (name === 'email' && inputValue.length > 0) {
				if (!isValidEmail(value)) {
					setErrors((prevErrors) => ({
						...prevErrors,
						[name]: `Invaid email address`,
					}));
					return;
				}
			}

			// Validation for required fields
			const field = StakeholderFormDetailsData.flatMap(
				(section) => section.fields
			).find((field) => field.name === name);

			if (field?.isRequired && !value.trim()) {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: `${field.label} is required`,
				}));
			} else {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: '',
				}));
			}
		},

		[
			containsDigit,
			isValidEmail,
			setStakeholderFormData,
			StakeholderFormDetailsData,
			setErrors,
			isAlphaWithSpaces,
			isAlphanumeric
		]
	);

	const getCustomOptions = useCallback(
		({
			data,
			innerRef,
			innerProps,
		}: {
			data: ISelectOption;
			innerRef: React.Ref<HTMLDivElement>;
			innerProps: any;
		}) => {
			return (
				<div
					className="InviteAdminisitrator__dropdown-option"
					ref={innerRef}
					{...innerProps}
				>
					<div className="InviteAdminisitrator__dropdown-option--header">
						<div className="InviteAdminisitrator__dropdown-option--text">
							<div className="InviteAdminisitrator__dropdown-option--label">
								{data.label}
							</div>
							<div className="InviteAdminisitrator__dropdown-option--description">
								{data.description}
							</div>
						</div>
					</div>
				</div>
			);
		},
		[]
	);

	return (
		<div className="StakeholderFormDetails">
			{StakeholderFormDetailsData.map((section, index) => (
				<FormSection
					key={index}
					title={section.title}
					body={section.fields.map((field) => field)}
					handleChange={handleChange}
					formData={stakeholderFormData}
					errors={errors}
					handleChangeSelect={handleChangeSelect}
					getCustomOptions={getCustomOptions}
					handleSelectCountryCode={handleSelectCountryCode}
					handleCountryChange={handleCountryChange}
					defaultCountryCode={stakeholderFormData.countryCode}
					defaultNumber={stakeholderFormData.phoneNumber}
					handleValidation={handleValidation}
				/>
			))}
		</div>
	);
};
