import React, { useCallback, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../@storybook';
import { useNetwork } from '../../hooks';
import { CompanyFormHeader, FormSection } from './components';
import {
	ALPHA_SPACE_ALLOWED,
	COMPANY_FIELDS_VALID,
	COMPANY_STATUS_MESSAGES,
	CompanyRegistrationFormDetails,
	CompanyRegistrationFormName,
	ValidateField,
} from './constant';
import { formState, selectedCompany } from './state';
import { API_URL } from '../../constants/api';

import { Loader } from '../../@storybook/loader';
import { useNotification } from '../../@storybook/notification';
import { memberTitlesState } from '../invite-signatories-modal';
import { companyInviteTypeState, selectedCompanyState } from '../header';
import {
	containsDigit,
	isBase64,
	removeEmptyValues,
	removeComma,
	isValidZip,
	isAlphanumeric,
	isAlphaWithSpaces,
	isValidURL,
} from '../../utils';

import './company-registration-form.scss';

export const CompanyRegistrationForm: React.FC = () => {
	const [formData, setFormData] = useRecoilState<any>(formState);
	const setCompanyList = useSetRecoilState(selectedCompany);
	const { post, loading, get, patch } = useNetwork();
	const { successNotification, errorNotification } = useNotification();
	const [errors, setErrors] = useState<{ [key: string]: string }>({});
	const companyInviteType = useRecoilValue(companyInviteTypeState);
	const selectedCompanyDetails = useRecoilValue(selectedCompanyState);
	const navigate = useNavigate(); // Hook for navigation
	const resetMemberTitle = useResetRecoilState(memberTitlesState);
	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = e.target;
			const inputValue = value.trim();
			// Block updating state if the value is numeric for specific fields
			if (COMPANY_FIELDS_VALID.includes(name)) {
				if (!isAlphanumeric(inputValue) || inputValue.length > 50) {
					return;
				}
			}
			if (ALPHA_SPACE_ALLOWED.includes(name)) {
				if (!isAlphaWithSpaces(inputValue) || inputValue.length > 50) {
					return;
				}
			}
			if (name === 'businessDescription') {
				if (inputValue.length > 500) {
					return;
				}
			}

			if (name === 'zipCode') {
				if (!isValidZip(inputValue) || inputValue.length > 10) {
					return;
				}
			}

			// Validate 'taxId' field for numeric and length checks
			if (name === 'taxId') {
				if (!isAlphanumeric(inputValue) || inputValue.length > 10) {
					return;
				}
			}

			setFormData((prevData: any) => ({
				...prevData,
				[name]: value,
			}));

			if (name === CompanyRegistrationFormName.ZIPCODE) {
				if (inputValue.length < 4) {
					// Validate against the regex
					setErrors((prevErrors) => ({
						...prevErrors,
						[name]:
							'The ZIP code entered is invalid. Kindly provide a valid ZIP code',
					}));
					return;
				} else {
					// Clear the error if input is valid
					setErrors((prevErrors) => ({
						...prevErrors,
						[name]: '',
					}));
				}
			}

			if (name === CompanyRegistrationFormName.WEBSITE) {
				if (!isValidURL(value) && value?.length) {
					setErrors((prevErrors) => ({
						...prevErrors,
						[name]: 'Invalid website url',
					}));
					return;
				}
				// Clear the error if input is valid
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: '',
				}));
			}

			// Find the corresponding field from CompanyRegistrationFormDetails
			const field = CompanyRegistrationFormDetails.flatMap(
				(section) => section.fields
			).find((field) => field.name === name);

			if (field?.isRequired && !value.trim()) {
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: `${field.label} is required`, // Use field's label
				}));
			} else {
				// Clear the error when input is valid
				setErrors((prevErrors) => ({
					...prevErrors,
					[name]: '',
				}));
			}
		},
		[
			setFormData,
			setErrors,
			ValidateField,
			containsDigit,
			isValidZip,
			isAlphanumeric,
			isAlphaWithSpaces,
			isValidURL,
			CompanyRegistrationFormDetails,
			CompanyRegistrationFormName,
		]
	);

	const handleLogoUpload = (file: string) => {
		setFormData((prevData: any) => ({
			...prevData,
			logo: file, // Update logo in state
		}));
	};

	const handleSave = useCallback(async () => {
		if (Object.values(errors).some((error) => error)) {
			return; // Stop the save process if there are errors
		}
		const newErrors: { [key: string]: string } = {};
		CompanyRegistrationFormDetails.forEach((section) => {
			section.fields.forEach((field) => {
				if (field.isRequired && !formData[field.name]) {
					newErrors[field.name] = `${field.label} is required`;
				}
			});
		});
		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return; // Stop the save process if there are errors
		}
		const payload = {
			companyName: formData.companyName,
			accountant: formData.accountant,
			taxId: formData.taxId,
			industrySector: formData.industrySector,
			tcc: formData.tcc,
			stateOfFormation: formData.stateOfFormation,
			dateOfFormation: formData.dateOfFormation,
			dba: formData.dba,
			jobTitle: formData.jobTitle,
			website: formData.website,
			businessDescription: formData.businessDescription ?? '',
			address: {
				street: formData.streetAddress,
				city: formData.city,
				state: formData.state,
				zip: formData.zipCode,
				country: formData.country,
			},
			incorporationDetails: {
				type: formData.incorporationType,
				date: formData.incorporationDate,
				country: formData.incorporationCountry,
				state: formData.incorporationState,
			},
		};

		const updatedPayload = isBase64(formData.logo)
			? { ...payload, logo: formData.logo ?? '' }
			: payload;

		const newUpdatedPayload = removeEmptyValues(updatedPayload);

		let resp;
		if (companyInviteType !== 'companyProfile') {
			// POST request for creating a company
			resp = await post(API_URL.COMPANY, newUpdatedPayload);
		} else {
			// PATCH request for updating an existing company
			resp = await patch(
				`${API_URL.COMPANY}/${selectedCompanyDetails?.id}`,
				newUpdatedPayload
			);
		}

		// If the POST/PATCH request is successful, then make the GET request
		const getCompanyData = await get(API_URL.COMPANY);
		if (resp?.data && getCompanyData?.data) {
			const successMessage =
				companyInviteType !== 'companyProfile'
					? COMPANY_STATUS_MESSAGES.CREATED
					: COMPANY_STATUS_MESSAGES.UPDATED;
			successNotification(successMessage);
			setCompanyList(getCompanyData?.data ?? []);
			setFormData({});
			resetMemberTitle();
			navigate('/setup'); // Navigate after successful data retrieval
		} else {
			errorNotification(resp.message ?? 'Something went wrong !');
		}
	}, [
		formData,
		navigate,
		setErrors,
		setCompanyList,
		post,
		successNotification,
		errorNotification,
		resetMemberTitle,
		removeEmptyValues
	]);

	const handleOnCompleteAutoComplete = useCallback(
		(data: any) => {
			// Destructure the necessary fields from the input data object.
			const {
				route = '',
				village = '',
				postal_code = '',
				country = '',
				state = '',
				city = '',
				enteredAddress = '',
				street_number = '',
			} = data ?? {};

			// Construct the address string based on available information.
			const address = street_number
				? `${street_number},${route}`
				: `${route},${village}`;

			// Ensure the street address is valid, fallback to enteredAddress if empty.
			const streetAddress = removeComma(address) || enteredAddress;

			// Prepare the payload object for form data updates.
			const payload = {
				streetAddress: streetAddress ?? '',
				zipCode: postal_code ?? '',
				city: city ?? '',
				state: state ?? '',
				country: country ?? '',
			};

			// Update form errors dynamically based on missing required fields.
			setErrors((prevErrors: any) => ({
				...prevErrors,
				streetAddress: streetAddress ? '' : 'Street Address is required',
				zipCode: postal_code ? '' : 'Zip code is required',
				city: city ? '' : 'City is required',
				state: state ? '' : 'State is required',
				country: country ? '' : 'Country is required',
			}));

			// Merge the payload into the existing form data.
			setFormData((prev: any) => ({ ...prev, ...payload }));
		},
		[removeComma, setErrors, setFormData] // Include dependencies used in the callback.
	);

	// Clear the streetAddress field in the form data when autofill is cleared.
	const handleAutofillClear = useCallback(() => {
		setFormData((prevData: any) => ({
			...prevData,
			streetAddress: '', // Reset streetAddress field to an empty string.
		}));
	}, [setFormData]);

	// Memoize the address autofill properties to avoid unnecessary recalculations.
	const addressAutoFillProps = useMemo(() => {
		return {
			handleOnCompleteAutoComplete, // Callback to handle address autofill completion.
			handleClear: formData.streetAddress ? handleAutofillClear : undefined, // Conditional clear handler.
		};
	}, [
		formData.streetAddress,
		handleAutofillClear,
		handleOnCompleteAutoComplete,
	]);
	
	return (
		<div className="CompanyRegistrationForm">
			<div className="CompanyRegistrationForm__body">
				<CompanyFormHeader
					onLogoUpload={handleLogoUpload}
					logo={formData.logo ?? ''}
				/>
				{CompanyRegistrationFormDetails.map((section, index) => (
					<FormSection
						key={index}
						title={section.title}
						subtitle={section.subtitle}
						body={section.fields}
						handleChange={handleChange}
						errors={errors}
						formData={formData}
						addressAutoFillProps={addressAutoFillProps}
						isInputDisabled={loading}
					/>
				))}

				<div className="CompanyRegistrationForm__footer">
					<Button
						label={!loading ? 'Save' : <Loader dimension={19} />}
						handleClick={handleSave}
						type="button__filled button__filled--gray button__large button__block"
						disabled={loading}
					/>
				</div>
			</div>
		</div>
	);
};
