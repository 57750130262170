import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	InviteAdministratorModal,
	IsOpenInviteAdministratorModal,
} from '../invite-administrator-modal';
import {
	InviteBoardMemberModal,
	IsOpenInviteBoardMemberModal,
} from '../invite-board-member-modal';
import { InviteLegalContactModal } from '../invite-legal-contact-modal/invite-legal-contact-modal';
import { IsOpenInviteLegalContactModal } from '../invite-legal-contact-modal/store';
import {
	IIsOpenInviteSignatoriesState,
	InviteSignatoriesModal,
	isOpenInviteSignatoriesModalState,
	isOpenInviteSignatoriesState,
	memberTitlesState,
} from '../invite-signatories-modal';
import { signatoryDataState } from '../settings/store';
import { TeamsBodySection, TeamsSectionWrapper } from './components';
import { isActiveTab } from './stores/state';

import './teams.scss';

export const Teams = () => {
	const activeTab = useRecoilValue<string>(isActiveTab);
	const memberTitle = useRecoilValue(memberTitlesState);
	const signatoryDetails = useRecoilValue(signatoryDataState);
	
	const [isOpenInviteSignatoriesModal, setIsOpenInviteSignatoriesModal] =
		useRecoilState(isOpenInviteSignatoriesModalState);
	const [isOpenInviteAdministratorModal, setIsOpenInviteAdministratorModal] =
		useRecoilState(IsOpenInviteAdministratorModal);
	const [isOpenInviteBoardMemberModal, setIsOpenInviteBoardMemberModal] =
		useRecoilState(IsOpenInviteBoardMemberModal);
	const [isOpenInviteLegalContactModal, setIsOpenInviteLegalContactModal] =
		useRecoilState(IsOpenInviteLegalContactModal);
	const [inviteSignatories,setInviteSignatories] = useRecoilState(isOpenInviteSignatoriesState);
	
	const mapSignatoryDataToPayload = useCallback(
		(signatory: any, isDropdown: boolean) => {

			const createDropdownOption = (name: string | undefined) => ({
				label: name || '',
				value: name || '',
			});

			if (isDropdown)
				return {
					certificate1FullName: isDropdown
						? signatory.firstSignatory?.name
						: [createDropdownOption(signatory.firstSignatory?.name)],
					certificate1Title: signatory.firstSignatory?.title || '',
					certificate2FullName: isDropdown
						? signatory.secondSignatory?.name
						: [createDropdownOption(signatory.secondSignatory?.name)],
					certificate2Title: signatory.secondSignatory?.title || '',
					equityPlanFullName: isDropdown
						? signatory.equitySignatory?.name
						: [createDropdownOption(signatory.equitySignatory?.name)],
					convertiblesFullName: isDropdown
						? signatory.convertableSignatory?.name
						: [createDropdownOption(signatory.convertableSignatory?.name)],
					convertiblesTitle: signatory.convertableSignatory?.title || '',
					warrantsFullName: isDropdown
						? signatory.warrantSignatory?.name
						: [createDropdownOption(signatory.warrantSignatory?.name)],
				};
		},
		[]
	);

	const handleAddMember = useCallback(
		(activeTab: string) => {
		  const IsDropDownField = memberTitle?.length > 0;
		  const signatoryData = signatoryDetails?.[0] ?? [];
		  const payload = mapSignatoryDataToPayload(
			signatoryData,
			IsDropDownField
		  );
	  
		  switch (activeTab) {
			case 'LawFirm':
			  setIsOpenInviteLegalContactModal(true);
			  break;
	  
			case 'Signatories':
			  if (signatoryDetails?.length && IsDropDownField) {
				setInviteSignatories(payload as IIsOpenInviteSignatoriesState);
			  }
			  setIsOpenInviteSignatoriesModal(true);
			  break;
	  
			case 'Administration':
			  setIsOpenInviteAdministratorModal(true);
			  break;
	  
			case 'BoardMember':
			  setIsOpenInviteBoardMemberModal(true);
			  break;
	  
			default:
			  setIsOpenInviteAdministratorModal(true);
			  break;
		  }
		},
		[
		  memberTitle,
		  signatoryDetails,
		  mapSignatoryDataToPayload,
		  setIsOpenInviteLegalContactModal,
		  setIsOpenInviteSignatoriesModal,
		  setInviteSignatories,
		  setIsOpenInviteAdministratorModal,
		  setIsOpenInviteBoardMemberModal,
		  inviteSignatories
		]
	  );

	const getButtonLabel = useCallback((tab: string): string => {
		switch (tab) {
			case 'LawFirm':
				return 'Add law firm';
			case 'Signatories':
				if(signatoryDetails?.length){
					return 'Update signatory'
				}
				return 'Add signatory';
			case 'Administration':
				return 'Add administrator';
			case 'BoardMember':
				return 'Add member';
			default:
				return 'Invite Member';
		}
	}, [signatoryDetails?.length]);

	return (
		<>
			<div className="Teams">
				<TeamsSectionWrapper
					headerTitle="Team"
					buttonLabel={getButtonLabel(activeTab)}
					onButtonClick={() => handleAddMember(activeTab)}
				>
					<TeamsBodySection />
				</TeamsSectionWrapper>
			</div>
			{isOpenInviteAdministratorModal && (
				<InviteAdministratorModal isFromTeam={true} />
			)}
			{isOpenInviteBoardMemberModal && (
				<InviteBoardMemberModal isFromTeam={true} />
			)}
			{isOpenInviteLegalContactModal && (
				<InviteLegalContactModal isFromTeam={true} />
			)}
			{isOpenInviteSignatoriesModal && (
				<InviteSignatoriesModal isFromTeam={true} />
			)}
		</>
	);
};
