import { IDataRoomFile, IDataRoomRootFolder } from './stores/types';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { v4 } from 'uuid';

import { Button, Image, Modal } from '../../@storybook';
import { Loader } from '../../@storybook/loader';

import { ReactResponsiveTable } from '../react-responsive-table';
import { PageHeader } from '../../components';
import { DataRoomColumns } from './constants';
import { useDataRoom } from './stores';
import { formatDate } from '../../utils';
import { BreadCrumb, EmptyTable } from './components';
import { BreadCrumbFolderState } from './stores/states';

import './data-room.scss';

/**
 * DataRoom Component: Displays a table of data room items, including folders and files,
 * with the ability to add folders and upload files.
 */
export const DataRoom = () => {
	// State to hold rows for the data table
	const [dataRoomRows, setDataRoomsRows] = useState<any[]>([]);

	// Get breadcrumb folder state from Recoil
	const breadCrumbFolders = useRecoilValue(BreadCrumbFolderState);

	// Retrieve necessary data and functions from the data room store
	const {
		folderData, // Array of folders and files
		renderCircles, // Function to render UI for multiple files
		handleModalOpen, // Function to handle modal state
		modalProps, // Modal properties (e.g., isOpen, content)
		gcpUrl, // Google Cloud Storage URL
		getFileIcon, // Function to get the file icon
		handleClickOnRow, // Function to handle row click events
		getDataRoom, // Function to fetch data room content
		companyId, // ID of the company
		loadingFolders,
	} = useDataRoom();

	/**
	 * Fetch data room content when the companyId changes.
	 */
	useEffect(() => {
		if (companyId) {
			getDataRoom();
		}
	}, [companyId]);

	/**
	 * Transform folder and file data into table rows whenever folderData or gcpUrl changes.
	 */
	useEffect(() => {
		/**
		 * Render cell content based on the type of data (file or folder) and column key.
		 * @param {object} data - Data item (file or folder).
		 * @param {string} key - Column key.
		 * @returns {JSX.Element | string} - Rendered cell content.
		 */
		const renderCell = (
			data: IDataRoomRootFolder & IDataRoomFile,
			key: string
		) => {
			if (data?.file) {
				// Render for file items
				switch (key) {
					case 'name':
						return (
							<div className="Data__Room--row">
								<Image
									fileName={getFileIcon(data?.file)}
									width="20px"
									height="24px"
									gcpUrl={gcpUrl}
								/>
								{data[key]}
							</div>
						);
					case 'items':
						return <div className="Data__Room--row">1 Item</div>;
					case 'updatedAt':
						return (
							<div className="Data__Room--row">
								{data[key] ? (formatDate(data[key]) ?? '--') : '--'}
							</div>
						);
					case 'uploadedBy':
						return <div className="Data__Room--row"></div>;
					default:
						return data[key as 'name'];
				}
			} else {
				// Render for folder items
				switch (key) {
					case 'name':
						return (
							<div className="Data__Room--row">
								<i className="ri-folder-fill Data__Room--folder_icon" />
								{data[key]}
							</div>
						);
					case 'items':
						return <div className="Data__Room--row">{renderCircles(data)}</div>;
					case 'updatedAt':
						return (
							<div className="Data__Room--row">
								{data[key] ? (formatDate(data[key]) ?? '--') : '--'}
							</div>
						);
					case 'uploadedBy':
						return <div className="Data__Room--row"></div>;
					default:
						return data[key as 'name'];
				}
			}
		};

		if (Array.isArray(folderData)) {
			// Filter out items with non-successful file statuses
			const filteredData = folderData.filter(
				(data) =>
					!(
						(data as IDataRoomFile)?.file &&
						(data as IDataRoomFile)?.status !== 'SUCCESS'
					)
			);

			// Map filtered data to table rows
			const rows = filteredData.map((data) => {
				const row: Record<string, any> = {
					id: data?.id ?? v4(),
					itemData: data,
					file: (data as IDataRoomFile)?.file ?? undefined,
					relativeFilePath:
						(data as IDataRoomFile)?.relativeFilePath ?? undefined,
					combineData: (data as IDataRoomRootFolder)?.combineData ?? undefined,
				};

				// Populate row data for each column dynamically
				DataRoomColumns.forEach(({ key }) => {
					row[key] = renderCell(
						data as IDataRoomRootFolder & IDataRoomFile,
						key
					);
				});

				return row;
			});

			// Update state with the transformed rows
			setDataRoomsRows(rows);
		}
	}, [folderData, gcpUrl]);

	return (
		<div className="Data__Room">
			{/* Page Header */}
			<PageHeader
				name={
					breadCrumbFolders[breadCrumbFolders?.length - 1]?.name || 'Data room'
				}
			/>

			{/* Breadcrumb Navigation and Action Buttons */}
			<div className="Data__Room--breadcrumb">
				<BreadCrumb />
				<div className="Data__Room--btns">
					{breadCrumbFolders?.length < 3 && (
						<Button
							label="Add Folder"
							handleClick={() => handleModalOpen('add')}
							type={`button__filled button__filled--${breadCrumbFolders?.length > 1 ? 'secondary' : 'primary'} button__large button_block button--add`}
						/>
					)}
					{breadCrumbFolders?.length > 1 && (
						<Button
							label="Upload"
							handleClick={() => handleModalOpen('upload')}
							type="button__filled button__filled--primary button__large button_block button--upload"
						/>
					)}
				</div>
			</div>

			{/* Table Section */}
			<div className="Data__Room--table_wrapper">
				{loadingFolders ? (
					<Loader className='loader-black'/>
				) : folderData?.length ? (
					<ReactResponsiveTable
						columns={DataRoomColumns}
						data={dataRoomRows}
						isLoading={false}
						isPagination={false}
						handleClickOnRow={handleClickOnRow}
						showSearch={false}
					/>
				) : (
					<EmptyTable />
				)}
			</div>

			{/* Modal for Adding/Uploading Items */}
			{modalProps?.isOpen && <Modal {...modalProps} />}
		</div>
	);
};
