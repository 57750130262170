import { useCallback, useMemo } from 'react';
import { ReactResponsiveTable } from '../../../react-responsive-table';
import { StakeholderHeader } from '../../constant';
import {
	AddStakeholderState,
	IsFromTable,
	IsStakeholderModalOpen,
	IStakeholder,
	StakeholderFormState,
} from '../../../stakeholder-modal/store';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { API_URL } from '../../../../constants/api';
import { useStakeholder } from '../../../stakeholder-modal/hooks';
import { useNetwork } from '../../../../hooks';
import { useNotification } from '../../../../@storybook';

import './stakeholder-table.scss';

export const StakeholderTable = () => {
	const stakeholderFormData = useRecoilValue(AddStakeholderState);
	const setIsOpen = useSetRecoilState(IsStakeholderModalOpen);
	const setStakeholderForm = useSetRecoilState(StakeholderFormState);
	const setIsFromTable = useSetRecoilState(IsFromTable);
	const { getStakeholderDetails } = useStakeholder();
	const { remove } = useNetwork();
	const { successNotification } = useNotification();
	const renderRow = useMemo(() => {
		return stakeholderFormData
			?.map((stakeholder: IStakeholder) => ({
				fullName: `${stakeholder?.firstName} ${stakeholder?.lastName}`,
				email: stakeholder?.email,
				instituteName: stakeholder?.institutionName,
				type: stakeholder?.stakeholderType,
				association: stakeholder?.currentRelationship,
				id: stakeholder?.id,
			}))
			?.reverse();
	}, [stakeholderFormData]);

	const handleonRowAction = useCallback(
		async (action: 'edit' | 'delete', rowId: number) => {
			if (action === 'edit') {
				setIsOpen(true);
				const rowToEdit = stakeholderFormData?.find((row) => row.id === rowId);
				if (rowToEdit) {
					const prefilledFormData = {
						firstName: rowToEdit?.firstName || '',
						lastName: rowToEdit?.lastName || '',
						email: rowToEdit?.email || '',
						institutionName: rowToEdit?.institutionName || '',
						taxId: rowToEdit?.taxId || '',
						streetAddress: rowToEdit?.address?.street || '',
						city: rowToEdit?.address?.city || '',
						state: rowToEdit?.address?.state || '',
						postalCode: rowToEdit?.address?.zip || '',
						// Map currentRelationship to group with label and value
						group: rowToEdit?.currentRelationship
							? [
									{
										label: rowToEdit?.currentRelationship,
										value: rowToEdit?.currentRelationship,
									},
								]
							: [],
						// Map institutionType to type with label and value
						type: rowToEdit?.stakeholderType
							? [
									{
										label: rowToEdit?.stakeholderType,
										value: rowToEdit?.stakeholderType,
									},
								]
							: [],
						countryCode: rowToEdit?.countryCode || '+1',
						phoneNumber: rowToEdit?.phone || '',
						id: rowToEdit?.id || '',
					};
					setIsFromTable(action);
					setStakeholderForm(prefilledFormData);
				}
			} else if (action === 'delete') {
				const resp = await remove(`${API_URL.SHARE_STAKEHOLDER}/${rowId}`);
				if (resp?.ok) {
					await getStakeholderDetails();
					successNotification('Stakeholder deleted successfully !');
				}
			}
		},
		[
			stakeholderFormData,
			setIsOpen,
			setStakeholderForm,
			setIsFromTable,
			remove,
			getStakeholderDetails,
		]
	);

	return (
		<div className="stakeholder__table--container">
			<div className="stakeholder__table--contaner__body">
				{
					<ReactResponsiveTable
						columns={StakeholderHeader}
						data={renderRow}
						isLoading={false}
						isPagination={false}
						onRowAction={(action, rowId) => handleonRowAction(action, rowId)}
						isDisabledDropdown = {false}
					/>
				}
			</div>
		</div>
	);
};