import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { Button, Modal, useNotification } from '../../@storybook';

import { API_URL } from '../../constants/api';
import { useNetwork } from '../../hooks';
import { InviteAdministratorHeader } from '../invite-administrator-modal';

import { selectedCompanyState } from '../header';
import { InviteBoardMember } from './components';
import {
	IInviteBoardMemberState,
	InviteBoardMemberState,
	IsOpenInviteBoardMemberModal,
} from './store';

import './invite-board-member-modal.scss';
import { Loader } from '../../@storybook/loader';
import { isActiveTab } from '../Teams/stores/state';
import { useNavigate } from 'react-router-dom';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTeamData } from '../settings/store/hooks';
import { isValidEmail } from '../../utils';
interface IInviteBoardMemberModal {
	isFromTeam?: boolean; // Optional property
}

export const InviteBoardMemberModal: FC<IInviteBoardMemberModal> = ({
	isFromTeam = false,
}) => {
	const [isOpenInviteBoardMemberModal, setIsOpenInviteBoardMemberModal] =
		useRecoilState(IsOpenInviteBoardMemberModal);
	const inviteBoardMemberState = useRecoilValue(InviteBoardMemberState);
	const selectedCompanyDetails = useRecoilValue(selectedCompanyState);
	const resetInviteBoardMemberState = useResetRecoilState(
		InviteBoardMemberState
	);
	const setActiveTab = useSetRecoilState<string>(isActiveTab);

	const { post, loading } = useNetwork();
	const navigate = useNavigate();
	const { fetchTeamData } = useTeamData();
	const { successNotification, errorNotification } = useNotification();

	const [isPhoneValid, setIsPhoneValid] = useState(true);

	const onPhoneValidationChange = (isValid: boolean) => {
		setIsPhoneValid(isValid);
	};

	// Function to check if required fields are filled
	const validateForm = () => {
		// Define required fields
		const requiredFields: (keyof IInviteBoardMemberState)[] = [
			'firstName',
			'lastName',
			'email',
			'boardRole',
			'startDate',
		];

		// Check if any required field is empty or invalid
		for (const field of requiredFields) {
			const value = inviteBoardMemberState[field];

			// Check if the value is a string and apply trim, otherwise check for emptiness
			if (typeof value === 'string' && value.trim() === '') {
				return false; // Return false if the string is empty
			}

			// If the field is an array (e.g., permissions), ensure it has items
			if (Array.isArray(value) && value.length === 0) {
				return false; // Return false if the array is empty
			}

			// If it's an object or other data type, you can add further checks if necessary
			// For example, you can check if the object has required properties (if necessary)
		}

		return true; // Return true if all required fields are filled
	};

	const handleAddMember = useCallback(async () => {
		const {
			firstName,
			lastName,
			email,
			boardRole,
			startDate,
			endDate,
			countryCode,
			phoneNumber,
		} = inviteBoardMemberState ?? {};
		
		const inviteBoardMemberStatePayload = {
			type: 'BoardMember',
			firstName,
			lastName,
			email,
			startDate,
			endDate,
			countryCode,
			phone: phoneNumber,
			roleId: boardRole[0]?.value ?? '',
			companyId: selectedCompanyDetails?.id ?? '',
			title: boardRole?.[0]?.label ?? '',
		};
	
		const resp = await post(API_URL.STAKEHOLDER, inviteBoardMemberStatePayload);
		if (resp?.data) {
			successNotification('Invite sent successfully!');
			setIsOpenInviteBoardMemberModal(false);
			resetInviteBoardMemberState();
			setActiveTab('BoardMember');
			navigate('/settings');
			if (isFromTeam) {
				fetchTeamData(resp?.data?.companyId);
			}
		} else {
			errorNotification(resp.message ?? 'Something went wrong!');
		}
	}, [
		inviteBoardMemberState,
		post,
		successNotification,
		setIsOpenInviteBoardMemberModal,
		resetInviteBoardMemberState,
		setActiveTab,
		navigate,
		isFromTeam,
		fetchTeamData,
		selectedCompanyDetails?.id,
	]);

	const handleCloseModal = () => {
		setIsOpenInviteBoardMemberModal(false);
		resetInviteBoardMemberState();
	};

	const isFormValid = useMemo(() => {
		return !validateForm() || !isPhoneValid || !isValidEmail(inviteBoardMemberState.email);
	  }, [validateForm, isPhoneValid, inviteBoardMemberState.email, isValidEmail]);
	  

	return (
		<div className="InviteBoardMemberModal">
			<Modal
				showHeader={true}
				isOpen={isOpenInviteBoardMemberModal}
				modalName="InviteBoardMemberModal Modal"
				className="InviteBoardMemberModal--modal"
				closeModal={handleCloseModal}
				showCloseBtn={true}
				isStopOutsideClick={true}
				title={
					<InviteAdministratorHeader
						title="Board member"
						subTitle="Add board member"
					/>
				}
			>
				<div className="InviteBoardMemberModal--body">
					<InviteBoardMember
						onPhoneValidationChange={onPhoneValidationChange}
					/>
					<div className="InviteBoardMemberModal--body__btn">
						<Button
							label={!loading ? 'Add member' : <Loader dimension={18} />}
							handleClick={handleAddMember}
							type="button__filled button__filled--primary button__large"
							disabled={isFormValid}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};
