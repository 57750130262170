import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNotification } from '../../../../@storybook';

import { selectedCompanyState } from '../../../header';
import { API_URL } from '../../../../constants/api';
import { useNetwork } from '../../../../hooks';
import { DataRoomRootFolderState } from '../states';
import { useBreadCrumb } from './useBreadCrumb';

/**
 * Custom hook to handle the creation of a new folder in the data room.
 * This hook manages folder creation logic, handles state updates,
 * and provides utility methods for handling input and notifications.
 */
export const useAddFolder = () => {
	// Get the selected company details from Recoil state
	const selectedCompanyDetails = useRecoilValue(selectedCompanyState);

	// Recoil state setter for updating the root folders in the data room
	const setDataRoomRootFolders = useSetRecoilState(DataRoomRootFolderState);

	// Local state for managing the folder name input
	const [folderName, setFolderName] = useState('');

	// Custom hook for network requests and loading state management
	const { post, loading: addingFolder } = useNetwork();

	// Custom hook for displaying error notifications
	const { errorNotification } = useNotification();

	const { updateBreadCrumb, currentFolder } = useBreadCrumb();

	/**
	 * Handles the creation of a new folder.
	 * Sends a request to the server to create a folder and updates the relevant states.
	 *
	 * @param {string} name - Name of the folder to be created.
	 * @param {Function} handleModalClose - Callback to close the modal after successful folder creation.
	 */
	const handleCreateFolder = useCallback(
		async (name: string, handleModalClose: () => void) => {
			// Prepare the payload with the folder details to be sent to the API
			const payload = {
				name,
				companyId: selectedCompanyDetails?.id, // Selected company ID
				parentFolderId: currentFolder?.id || undefined, // Parent folder ID (if any)
			};

			// Make an API call to create the folder
			const response = await post(API_URL.FOLDER, payload);
			const { data, message } = response ?? {};

			// If folder creation is successful, update the root folder state
			if (data?.id) {
				setDataRoomRootFolders((previousFolders) => {
					// Clone the previous state of the folders
					const updatedFolders = structuredClone(previousFolders);
					// Add the new folder at the top of the list
					updatedFolders.unshift(data);
					return updatedFolders;
				});
				// Update the breadcrumb folders with the new folder
				updateBreadCrumb(data , 'add');
				// Close the modal upon successful folder creation
				handleModalClose();
			} else {
				// Display an error notification if folder creation failed
				errorNotification(message || 'Something went wrong.');
			}
		},
		[
			post,
			selectedCompanyDetails?.id,
			currentFolder?.id,
			setDataRoomRootFolders,
			errorNotification,
		]
	);

	/**
	 * Handles the change event for the folder name input field.
	 * Updates the folder name state whenever the input value changes.
	 *
	 * @param {ChangeEvent<HTMLInputElement>} e - The change event from the input field.
	 */
	const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target ?? {}; // Get the input value
		if (value?.length > 50) return;
		setFolderName(value); // Update the folder name state
	}, []);

	/**
	 * Handles the key press event for the folder name input field.
	 * If the "Enter" key is pressed and the folder name is valid, it triggers folder creation.
	 *
	 * @param {KeyboardEvent<HTMLInputElement>} e - The key press event from the input field.
	 * @param {Function} handleModalClose - Callback to close the modal after successful folder creation.
	 */
	const onKeyPress = useCallback(
		(e: KeyboardEvent<HTMLInputElement>, handleModalClose: () => void) => {
			const { key } = e ?? {}; // Get the key pressed
			// Trigger folder creation if "Enter" is pressed and folder name is valid
			if (key === 'Enter' && folderName?.trim() && !addingFolder) {
				handleCreateFolder(folderName, handleModalClose);
			}
		},
		[folderName, addingFolder]
	);

	// Return utility methods and state for folder creation
	return {
		handleCreateFolder, // Method to handle folder creation
		handleChange, // Method to handle input changes
		folderName, // Current folder name state
		addingFolder, // Loading state during folder creation
		onKeyPress, // Method to handle key press events
	};
};
