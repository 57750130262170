import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Button, Modal, useNotification } from '../../@storybook';

import { API_URL } from '../../constants/api';
import { useNetwork, userRoleState } from '../../hooks';
import { InviteAdministratorHeader } from '../invite-administrator-modal';

import { selectedCompanyState } from '../header';
import { InviteLegalContact } from './components';
import {
	IInviteLegalContactState,
	InviteLegalContactState,
	IsOpenInviteLegalContactModal,
} from './store';

import './invite-legal-contact-modal.scss';
import { Loader } from '../../@storybook/loader';
import { isActiveTab } from '../Teams/stores/state';
import { useNavigate } from 'react-router-dom';
import { FC, useCallback, useState } from 'react';
import { useTeamData } from '../settings/store/hooks';
import { isValidEmail } from '../../utils';

interface IInviteLegalContactModal {
	isFromTeam?: boolean; // Optional property
}

export const InviteLegalContactModal : FC<IInviteLegalContactModal> = ({
	isFromTeam = false,
}) => {
	const [isOpenInviteLegalContactModal, setIsOpenInviteLegalContactModal] =
		useRecoilState(IsOpenInviteLegalContactModal);
	const inviteLegalContact = useRecoilValue<IInviteLegalContactState>(
		InviteLegalContactState
	);
	const selectedCompanyDetails = useRecoilValue(selectedCompanyState);
	const roles = useRecoilValue(userRoleState);
	const resetInviteLegalContactState = useResetRecoilState(
		InviteLegalContactState
	);
	const setActiveTab = useSetRecoilState<string>(isActiveTab);
	const [isPhoneValid, setIsPhoneValid] = useState(true);
	const { fetchTeamData } = useTeamData();

	const { post,loading } = useNetwork();
	const { successNotification, errorNotification } = useNotification();

	const navigate = useNavigate();

	// Find the manager role ID
	const LawFirmRole = roles?.find((role) => role.name.toLowerCase() === 'user');

	const onPhoneValidationChange = (isValid:boolean)=>{
		setIsPhoneValid(isValid);
	}

	// Utility function to check if all required fields are filled
	const areAllFieldsFilled = (fields: IInviteLegalContactState): boolean => {
		// List of required fields
		const requiredFields: (keyof IInviteLegalContactState)[] = [
			'firstName',
			'lastName',
			'email',
			'lawFirmName',
			'countryCode',
			'phoneNumber',
		];

		// Check if every required field is filled
		return requiredFields.every((field) => {
			const value = fields[field];
			return Array.isArray(value)
				? value.length > 0 && value[0]?.value // Check for array-based fields (e.g., lawFirmName)
				: value !== null && value !== undefined && value !== '';
		});
	};

	// Check if button should be disabled
	const isButtonDisabled = !areAllFieldsFilled(
		inviteLegalContact ?? {
			firstName: '',
			lastName: '',
			email: '',
			lawFirmName: [],
			countryCode: '',
			phoneNumber: '',
		}
	);

	const handleAddMember = useCallback(async () => {
		const {
			firstName,
			lastName,
			email,
			lawFirmName,
			countryCode,
			phoneNumber,
		} = inviteLegalContact;
	
		const inviteLegalContactPayload = {
			type: 'LawForm',
			firstName,
			lastName,
			email,
			lawFirmName: lawFirmName[0]?.value,
			companyId: selectedCompanyDetails?.id ?? '',
			roleId: LawFirmRole?.id ?? '',
			countryCode,
			phone: phoneNumber,
		};
	
		const resp = await post(API_URL.STAKEHOLDER, inviteLegalContactPayload);
		if (resp?.data) {
			successNotification('Invite sent successfully!');
			setIsOpenInviteLegalContactModal(false);
			resetInviteLegalContactState();
			setActiveTab('LawFirm');
			navigate('/settings');
			if (isFromTeam) {
				fetchTeamData(resp?.data?.companyId);
			}
		} else {
			errorNotification(resp?.message ?? 'Something went wrong!');
		}
	}, [
		inviteLegalContact,
		post,
		successNotification,
		setIsOpenInviteLegalContactModal,
		resetInviteLegalContactState,
		setActiveTab,
		navigate,
		isFromTeam,
		fetchTeamData,
		selectedCompanyDetails?.id,
		LawFirmRole?.id,
	]);
	

	const handleCloseModal = () => {
		setIsOpenInviteLegalContactModal(false);
		resetInviteLegalContactState();
	};

	return (
		<div className="InviteLegalContactModal">
			<Modal
				showHeader={true}
				isOpen={isOpenInviteLegalContactModal}
				modalName="InviteLegalContactModal Modal"
				className="InviteLegalContactModal--modal"
				closeModal={handleCloseModal}
				showCloseBtn={true}
				isStopOutsideClick={true}
				title={
					<InviteAdministratorHeader title="Law firm" subTitle="Add law firm" />
				}
			>
				<div className="InviteLegalContactModal--body">
					<InviteLegalContact  onPhoneValidationChange={onPhoneValidationChange}/>
					<div className="InviteLegalContactModal--body__btn">
						<Button
							label={loading ? <Loader dimension={18}/> :"Add law firm"}
							handleClick={handleAddMember}
							type="button__filled button__filled--primary button__large"
							disabled={isButtonDisabled || !isPhoneValid || !isValidEmail(inviteLegalContact?.email)}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};
