import { useEffect, useState } from 'react';
import { IInput } from './type';
import { CountryCode } from '../country-code';

import './input.scss';


export const Input = ({
	label,
	inputType = 'text',
	placeholder,
	handleChange,
	handleChangeCountry,
	value,
	isError = false,
	errorMessage,
	handleBlur,
	handleFocus,
	prefixIcon,
	disabled = false,
	autoComplete,
	inputName,
	maxLength,
	autoFocus = false,
	onKeyDown,
	isRequired = false,
	className = '',
	handleInputClick,
	labelSize = 'large',
	description='',
	isCountryCodeVisible,
	countryCode,
	minDate = '',
	handleKeyPress,
}: IInput) => {
	const [maxDate, setMaxDate] = useState('');

	useEffect(() => {
		const currentDate = new Date();
		setMaxDate(currentDate.toISOString().split('T')[0]);
	}, [inputType]);

	return (
		<div className="input">
			{label && (
				<label htmlFor={inputName} className={`input__label--${labelSize}`}>
					{label}
					{isRequired && (
						<span className="input__required--label"> (Required)</span>
					)}
				</label>
			)}
			{description && <div className='input__sub--label'>{description}</div>}
			<div className={`input__value-container ${className}`}>
				<div className="input__group">
					<div
						className={`input__text-field ${isError ? 'input__text-field--error' : ''}`}
					>
						{isCountryCodeVisible && handleChangeCountry && (
							<CountryCode
								handleChangeCountry={handleChangeCountry}
								countryCode={countryCode}
								isDisabled={disabled}
							/>
						)}
						{prefixIcon && <i className={prefixIcon} />}
						<input
							placeholder={placeholder}
							type={inputType}
							name={inputName}
							onBlur={handleBlur}
							onFocus={handleFocus}
							className="input__field"
							onChange={handleChange}
							value={value}
							disabled={disabled}
							autoComplete={autoComplete}
							maxLength={maxLength}
							autoFocus={autoFocus}
							onKeyDown={onKeyDown}
							max={maxDate}
							min={minDate}
							onClick={handleInputClick}
							onKeyPress={handleKeyPress}
						/>
					</div>
				</div>
				{isError && errorMessage && (
					<span className="input__error">{errorMessage}</span>
				)}
			</div>
		</div>
	);
};
