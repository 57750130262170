import { FC } from 'react'; // Importing React's Function Component type for type safety

import './page-header.scss'; // Importing the associated SCSS file for styling

// Interface to define the expected props for the PageHeader component
interface IPageHeader {
	name: string; // The name to be displayed in the header
}

// Functional component for rendering a page header
export const PageHeader: FC<IPageHeader> = ({ name }) => {
	return (
		// Applying the CSS class for consistent styling
		<div className="Page__Header">
			{name} {/* Rendering the name prop dynamically */}
		</div>
	);
};
