import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { Button, Modal, useNotification } from '../../@storybook';
import { InviteAdminisitrator } from './components';
import {
	InviteAdministratorState,
	IsOpenInviteAdministratorModal,
} from './store';
import { useNetwork } from '../../hooks';
import { selectedCompanyState } from '../header';
import { InviteAdministratorHeader } from './components/invite-administrator-header';
import { API_URL } from '../../constants/api';

import './invite-administrator-modal.scss';
import { Loader } from '../../@storybook/loader';
import { useNavigate } from 'react-router-dom';
import { FC, useCallback, useMemo, useState } from 'react';
import { isActiveTab } from '../Teams/stores/state';
import { useTeamData } from '../settings/store/hooks';
import { isValidEmail } from '../../utils';

interface IInviteAdministratorModal {
	isFromTeam?: boolean; // Optional property
}

export const InviteAdministratorModal: FC<IInviteAdministratorModal> = ({
	isFromTeam = false,
}) => {
	const [isOpen, setIsOpen] = useRecoilState(IsOpenInviteAdministratorModal);
	const inviteAdministrator = useRecoilValue(InviteAdministratorState);
	const selectedCompanyDetails = useRecoilValue(selectedCompanyState);
	const { post, loading } = useNetwork();
	const { successNotification, errorNotification } = useNotification();
	const resetInviteAdministrator = useResetRecoilState(
		InviteAdministratorState
	);
	const setActiveTab = useSetRecoilState<string>(isActiveTab);
	const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);
	const navigate = useNavigate();
	const {fetchTeamData} =useTeamData()

	const onPhoneValidationChange = (isValid: boolean) => {
		setIsPhoneValid(isValid);
	};
	// Function to validate form fields
	const validateForm = useMemo(() => {
		const {
			firstName,
			lastName,
			email,
			titles,
			permissions,
			countryCode,
			phoneNumber,
		} = inviteAdministrator ?? {};

		// Check required fields
		return (
			!!firstName.trim() &&
			!!lastName.trim() &&
			!!email.trim() &&
			!!titles.trim() &&
			permissions.length > 0 &&
			!!countryCode &&
			!!phoneNumber &&
			isValidEmail(email)
		);
	}, [inviteAdministrator,isValidEmail]);

	const handleAddMember = useCallback(async () => {
		const {
			firstName,
			lastName,
			email,
			titles,
			phoneNumber,
			countryCode,
			permissions,
		} = inviteAdministrator ?? {};
	
		const inviteAdministratorPayload = {
			type: 'Administration',
			firstName,
			lastName,
			email,
			phone: phoneNumber,
			countryCode,
			title: titles,
			roleId: permissions[0]?.value,
			companyId: selectedCompanyDetails?.id ?? '',
		};
	
			const resp = await post(API_URL.STAKEHOLDER, inviteAdministratorPayload);
			if (resp.data) {
				successNotification('Invite sent successfully!');
				setIsOpen(false);
				resetInviteAdministrator();
				navigate('/settings');
				setActiveTab('Administration');
				if (isFromTeam) {
					fetchTeamData(resp?.data?.companyId);
				}
			} else {
				errorNotification(resp.message ?? 'Something went wrong!');
			}
	}, [
		inviteAdministrator,
		selectedCompanyDetails?.id,
		isFromTeam,
		post,
		successNotification,
		errorNotification,
		resetInviteAdministrator,
		navigate,
		setActiveTab,
		fetchTeamData,
		setIsOpen,
	]);

	const handleCloseModal = () => {
		setIsOpen(false);
		resetInviteAdministrator();
	};

	return (
		<div className="InviteAdministratorModal">
			<Modal
				showHeader={true}
				isOpen={isOpen}
				modalName="InviteAdministrator Modal"
				className="InviteAdministratorModal--modal"
				closeModal={handleCloseModal}
				showCloseBtn={true}
				isStopOutsideClick={true}
				title={
					<InviteAdministratorHeader
						title="Administrator"
						subTitle="Invite Administrator"
					/>
				}
			>
				<div className="InviteAdministratorModal--body">
					<InviteAdminisitrator
						onPhoneValidationChange={onPhoneValidationChange}
					/>
					<div className="InviteAdministratorModal--body__btn">
						<Button
							label={!loading ? 'Add administrator' : <Loader dimension={18} />}
							handleClick={handleAddMember}
							type="button__filled button__filled--primary button__large button_block"
							disabled={!validateForm || !isPhoneValid}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};
