import { FC } from 'react';
import './data-room-modal-header.scss';

interface IDataRoomModalHeader {
  title: string; // Title of the modal header
  icon: string;  // Icon class to be applied to the icon element
}

export const DataRoomModalHeader: FC<IDataRoomModalHeader> = ({
  title,
  icon,
}) => {
  return (
    <div className="Data__Room__Modal__Header">
      {/* Image section, renders the icon inside a container */}
      <div className="Data__Room__Modal__Header--image">
        <i className={icon} />
      </div>

      {/* Title section, displays the provided title */}
      <div className="Data__Room__Modal__Header--title">{title}</div>
    </div>
  );
};
