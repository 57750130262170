import './stakeholder-modal.scss';
import { Button, Modal, useNotification } from '../../@storybook';
import {
	IsFromTable,
	IsStakeholderModalOpen,
	StakeholderFormState,
} from './store/state';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { InviteAdministratorHeader } from '../invite-administrator-modal';
import { StakeholderFormDetails } from './components/stakeholder-form-details/stakeholder-form-details';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { API_URL } from '../../constants/api';
import { useNetwork, userRoleState } from '../../hooks';
import { selectedCompanyState } from '../header';
import { useStakeholder } from './hooks';
import { Loader } from '../../@storybook/loader';
import { isValidEmail} from '../../utils';
export const StakeholderModal = () => {
	const [isOpen, setIsOpen] = useRecoilState(IsStakeholderModalOpen);
	const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);
	const stakeholderFormData = useRecoilValue(StakeholderFormState);
	const resetStakeholderFormData = useResetRecoilState(StakeholderFormState);
	const selectedCompanyDetails = useRecoilValue(selectedCompanyState);
	const roles = useRecoilValue(userRoleState);
	const isFromTable = useRecoilValue(IsFromTable);
	const resetIsFromTable = useResetRecoilState(IsFromTable);
	const { post, loading, patch } = useNetwork();
	const { successNotification, errorNotification } = useNotification();
	const { getStakeholderDetails } = useStakeholder();

	// Find the manager role ID
	const userRole = roles?.find((role) => role.name.toLowerCase() === 'user');

	const onPhoneValidationChange = (isValid: boolean) => {
		setIsPhoneValid(isValid);
	};

	// Function to validate form fields
	const validateForm = useMemo(() => {
		const {
			firstName,
			lastName,
			email,
			countryCode,
			phoneNumber,
			institutionName,
			city,
			postalCode,
			state,
			streetAddress,
			taxId,
			type,
			group,
		} = stakeholderFormData ?? {};

		// Check required fields
		return (
			!!firstName.trim() &&
			!!lastName.trim() &&
			!!email.trim() &&
			!!countryCode &&
			!!phoneNumber &&
			!!institutionName.trim() &&
			!!city.trim() &&
			!!postalCode.trim() &&
			!!state.trim() &&
			!!streetAddress.trim() &&
			!!taxId.trim() &&
			isValidEmail(email) && 
			type.length > 0 &&
			group.length > 0 &&
			postalCode?.length > 3 
		);
	}, [stakeholderFormData,isValidEmail]);

	useEffect(() => {
		getStakeholderDetails();
	}, []);

	const handleAddStakeholder = useCallback(async () => {
		const errorNotificationMsg = isFromTable ? 'Stakeholder updated successfully!' : 'Stakeholder added successfully!';
		const {
			firstName,
			lastName,
			email,
			// countryCode,
			phoneNumber,
			institutionName,
			city,
			postalCode: zip,
			state,
			streetAddress,
			taxId,
			type,
			group,
		} = stakeholderFormData ?? {};

		const stakeholderPaload = {
			firstName,
			lastName,
			email,
			institutionName,
			stakeholderType: type[0]?.value,
			currentRelationship: group[0]?.value,
			// countryCode,
			phone: phoneNumber,
			taxId,
			address: {
				zip,
				city,
				state,
				street: streetAddress,
			},
			companyId: selectedCompanyDetails?.id ?? '',
			roleId: userRole?.id ?? '',
		};
		let resp;
		if (isFromTable === 'edit') {
			resp = await patch(
				`${API_URL.SHARE_STAKEHOLDER}/${stakeholderFormData?.id}`,
				stakeholderPaload
			);
			resetIsFromTable();
		} else {
			resp = await post(API_URL.SHARE_STAKEHOLDER, stakeholderPaload);
		}
		if (resp?.data) {
			successNotification(errorNotificationMsg);
			await getStakeholderDetails();
			setIsOpen(false);
			resetStakeholderFormData();
		} else {
			errorNotification(resp?.message ?? 'Something went wrong !');
		}
	}, [
		stakeholderFormData,
		post,
		successNotification,
		setIsOpen,
		resetStakeholderFormData,
		errorNotification,
		isFromTable,
		patch
	]);

	const handleCloseModal = () => {
		setIsOpen(false);
		resetStakeholderFormData();
	};

	const isDisabledStakeholder = useMemo(() => {
		return !validateForm || !isPhoneValid || loading;
	}, [validateForm, isPhoneValid, loading]);

	const AddStakeholderLabel = useMemo(() => {
		if (loading) {
			return <Loader dimension={18} />;
		}
		return isFromTable ? 'Update Stakeholder' : 'Add Stakeholder';
	}, [loading, isFromTable]);

	return (
		<div className="StakeholderModal">
			<Modal
				showHeader={true}
				isOpen={isOpen}
				modalName="InviteAdministrator Modal"
				className="InviteAdministratorModal--modal"
				closeModal={handleCloseModal}
				showCloseBtn={true}
				isStopOutsideClick={true}
				title={
					<InviteAdministratorHeader
						title="Stakeholder"
						subTitle="Add Stakeholder"
					/>
				}
			>
				<div className="InviteAdministratorModal--body">
					<StakeholderFormDetails
						onPhoneValidationChange={onPhoneValidationChange}
					/>
					<div className="InviteAdministratorModal--body__btn">
						<Button
							label={AddStakeholderLabel}
							handleClick={handleAddStakeholder}
							type="button__filled button__filled--primary button__large button_block"
							disabled={isDisabledStakeholder}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};
