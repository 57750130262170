import { IMAGE_GCP_URL } from '../../constants/common/common';

interface IProps {
	fileName?: string;
	url?: string;
	className?: string;
	height?: string;
	width?: string;
	props?: any;
	onLoad?: any;
	onError?: () => void;
	loading?: 'lazy' | 'eager';
	gcpUrl?: string
}

export const Image = ({
	fileName,
	url: imageUrl,
	className,
	height,
	width,
	props,
	onLoad,
	onError,
	loading = 'eager',
	gcpUrl
}: IProps) => {
	const url = imageUrl ?? `${gcpUrl ?? IMAGE_GCP_URL}/${fileName}`;
	return (
		<img
			loading={loading}
			src={url}
			alt={fileName}
			className={className ?? ''}
			height={height}
			width={width}
			onLoad={onLoad}
			{...props}
			{...(onError ? { onError } : {})}
		/>
	);
};