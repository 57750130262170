import { Image } from '../../../../@storybook';

import './empty-table.scss';

export const EmptyTable = () => {
	return (
		<div className="Empty__Table">
			{/* Renders an image with specified width and height */}
			<Image fileName="empty-data-room.svg" width="148px" height="122px" />

			{/* Displays the no document message */}
			<div className="Empty__Table--text">No document has been added yet</div>
		</div>
	);
};
