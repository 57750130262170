// Import necessary components and hooks
import { FC } from 'react';
import { Button, Input } from '../../../../@storybook'; // Custom Button and Input components
import { Loader } from '../../../../@storybook/loader';

import { useAddFolder } from '../../stores'; // Custom hook to manage folder-related logic

// Import the SCSS stylesheet specific to this component
import './add-folder.scss';

/**
 * Interface for handling folder addition props.
 * @property {Function} handleModalClose - Callback function to close the modal after folder creation.
 */
interface IAddFolder {
	handleModalClose: () => void;
}

/**
 * AddFolder Component
 * This component renders an input field for folder name and a button to create the folder.
 */
export const AddFolder: FC<IAddFolder> = ({ handleModalClose }) => {
	// Destructure necessary values and functions from the custom hook
	const {
		handleCreateFolder,
		handleChange,
		folderName,
		addingFolder,
		onKeyPress,
	} = useAddFolder();

	return (
		<div className="Add__Folder">
			{/* Main container for the AddFolder component */}
			{/* Input field for entering the folder name */}
			<Input
				className="Add__Folder--input" // Specific styling for the input field
				label="Folder Name" // Label for the input
				handleChange={handleChange} // Callback to handle changes in the input
				placeholder="" // Placeholder text (currently empty)
				value={folderName} // Bind input value to folderName from the hook
				disabled={addingFolder}
				handleKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
					onKeyPress(e, handleModalClose)
				}
			/>
			{/* Button to trigger folder creation */}
			<div className="Add__Folder--btn">
				{/* Wrapper for button alignment */}
				<Button
					label={addingFolder ? <Loader dimension={26} /> : 'Add Folder'} // Button text
					handleClick={() => handleCreateFolder(folderName, handleModalClose)} // Trigger folder creation on click
					type="button__filled button__filled--primary button__large button_block" // Button styles
					disabled={!folderName?.trim() || addingFolder} // Disable button if folderName is empty or only whitespace
				/>
			</div>
		</div>
	);
};
