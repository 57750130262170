import { useCallback } from 'react';
import { API_URL } from '../../../constants/api';
import { useNetwork } from '../../../hooks';
import { roleDataState, signatoryDataState } from './state';
import { useRecoilState, useSetRecoilState } from 'recoil';

export const useTeamData = () => {
	const setTeamData = useSetRecoilState(roleDataState); // Replace `any[]` with appropriate type if you know the structure of the response
	const [signatoryData,setSignatoryData] = useRecoilState(signatoryDataState); // Replace `any[]` with appropriate type if you know the structure of the response
	const { get } = useNetwork();

	const fetchTeamData = useCallback(async (companyId: string) => {
		try {
			const response = await get(
				`${API_URL.STAKEHOLDER}?companyId=${companyId}`
			);
			const resp = await get(`${API_URL.SIGNATORY}?companyId=${companyId}`);
			setTeamData(response?.data?.[0]);
			setSignatoryData(resp?.data);
		} catch (err) {
			console.error('Error fetching team data:', err);
		}
	}, [setTeamData,setSignatoryData]);

	const fetchSignatoryData = useCallback(
		async (companyId: string) => {
			try {
				const resp = await get(`${API_URL.SIGNATORY}?companyId=${companyId}`);
				setSignatoryData(resp?.data);
			} catch (err) {
				console.error('Error fetching team data:', err);
			}
		},
		[setSignatoryData]
	);

	return { fetchTeamData, fetchSignatoryData ,signatoryData};
};
