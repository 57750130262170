import { useSetRecoilState } from 'recoil';
import { API_URL } from '../../../constants/api';
import { useNetwork } from '../../../hooks';
import { AddStakeholderState } from '../store';

export const useStakeholder = () => {
	const setStakeholderFormData = useSetRecoilState(AddStakeholderState);
	const { get, loading } = useNetwork();

	const getStakeholderDetails = async () => {
		const resp = await get(`${API_URL.SHARE_STAKEHOLDER}`);
		if (resp?.data) {
			setStakeholderFormData(resp?.data ?? []);
		}
	};

	return { getStakeholderDetails, loading };
};