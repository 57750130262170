import { Button } from '../../@storybook';
import { StakeholderTable } from './components';
import './stakeholder-details.scss';
import { StakeholderModal } from '../stakeholder-modal';
import { useRecoilState } from 'recoil';
import { IsStakeholderModalOpen } from '../stakeholder-modal/store';
import { useStakeholder } from '../stakeholder-modal/hooks';
import { useEffect } from 'react';

export const StakeholderDetails = () => {
	const [stakeholderModalOpen, setIsStakeholderModalOpen] = useRecoilState(
		IsStakeholderModalOpen
	);
	const { getStakeholderDetails } = useStakeholder();

	useEffect(() => {
		getStakeholderDetails();
	}, []);

	const handleAddStakeholder = () => {
		setIsStakeholderModalOpen(true);
		console.log('stakeholder added');
	};

	return (
		<>
			<div className="stakeholder">
				<div className="stakeholder__header">
					<div className="stakeholder__title">
						<div className="stakeholder__label">Stakeholders</div>
						<div className="stakeholder__description">
							Manage stakeholders for your company
						</div>
					</div>
					<div className="stakeholder__button">
						<Button
							label="Add Stakeholder"
							handleClick={handleAddStakeholder}
							type="button__filled button__filled--primary button__medium TeamsSectionWrapper--button"
							icon="ri-add-fill"
						/>
					</div>
				</div>
				<div className="stakeholder__table">
					<StakeholderTable />
				</div>
			</div>
			{stakeholderModalOpen && <StakeholderModal />}
		</>
	);
};