import { useEffect, useCallback, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
	CompanyRegistrationForm,
	CompanySetup,
	DataRoom,
	Header,
	Settings,
	Sidebar,
	StakeholderDetails,
} from './view';
import { useNetwork, userRoleState } from './hooks';
import { API_URL } from './constants/api';
import { Loader } from './@storybook/loader';
import { selectedCompany } from './view/company-registration-form/state';

import './App.scss';
import 'remixicon/fonts/remixicon.css';
import { FeatureUnderDeveploment } from './view/hooks';

export const App = () => {
	const location = useLocation();
	const [companyList, setCompanyList] = useRecoilState(selectedCompany);
	const isFullPageRoute = location.pathname === '/company-registration';
	const setRoles = useSetRecoilState(userRoleState);
	const { get, loading } = useNetwork();

	// useCallback to memoize the function and prevent unnecessary re-creations
	const getRoles = useCallback(async () => {
		const resp = await get(API_URL.ROLE);
		const getCompanyData = await get(API_URL.COMPANY);
		if (resp?.message === 'ok') {
			setRoles(resp?.data ?? []);
		}
		if (!isFullPageRoute) {
			setCompanyList(getCompanyData?.data ?? []);
		}
	}, [setRoles]);

	useEffect(() => {
		getRoles();
	}, []); // Dependency on the memoized getRoles function
	// Use useMemo to memoize the route rendering logic
	const renderRoutes = useMemo(() => {
		if (companyList?.length) {
			return (
				<div className="app__data">
					<Header />
					<div className="app__leftNavData">
						<Sidebar />
						<div className="app__content">
							<Routes>
							    <Route path="*" element={<CompanySetup />} /> 
								<Route
									path="/"
									element={<Navigate to="/company-registration" />}
								/>
								<Route path="/setup" element={<CompanySetup />} />
								<Route path="/overview" element={<FeatureUnderDeveploment/>} />
								<Route
									path="/captable-overview"
									element={<FeatureUnderDeveploment/>}
								/>
								<Route
									path="/data-room"
									element={<DataRoom />}
								/>
								<Route
									path="/captable-access"
									element={<FeatureUnderDeveploment/>}
								/>
								<Route
									path="/review-captable-health"
									element={<FeatureUnderDeveploment/>}
								/>
								<Route
									path="/company-registration"
									element={<CompanyRegistrationForm />}
								/>
								<Route path="/settings" element={<Settings />} />
								<Route path="/stakeholder" element={<StakeholderDetails/>} />
							</Routes>
						</div>
					</div>
				</div>
			);
		}
		return <CompanyRegistrationForm />;
	}, [companyList]); // Depend on companyId to memoize the rendered content

	const renderBody = useMemo(() => {
		if (loading) {
			return <Loader dimension={50} className="loader-black" />;
		}

		if (isFullPageRoute) {
			return <CompanyRegistrationForm />;
		}

		return renderRoutes;
	}, [loading, isFullPageRoute, renderRoutes]);

	return <div className="app">{renderBody}</div>;
};
