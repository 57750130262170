import { atom } from 'recoil';
import { IStakeholderFormState } from './type';

export interface IStakeholder {
	id: string | number;
	companyId: string;
	roleId: string;
	firstName: string;
	lastName: string;
	currentRelationship: string;
	institutionName: string;
	email: string;
	phone: string;
	countryCode: string | null;
	userId: string | null;
	stakeholderType: string;
	taxId: string;
	address: {
		zip: string;
		city: string;
		state: string;
		street: string;
		country: string;
	};
	createdAt?: string;
	updatedAt?: string;
}

export type StakeholderList = IStakeholder[];

// Atom for modal open/close state
export const IsStakeholderModalOpen = atom<boolean>({
	key: 'is-stakeholder-modal-open',
	default: false,
});

// Atom for Invite Administrator state
export const StakeholderFormState = atom<IStakeholderFormState>({
	key: 'stakeholder-form-state',
	default: {
		firstName: '',
		lastName: '',
		email: '',
		institutionName: '',
		taxId: '',
		streetAddress: '',
		city: '',
		state: '',
		postalCode: '',
		group: [],
		type: [],
		countryCode: '+1',
		phoneNumber: '',
		id:''
	},
});

// Atom for Invite Administrator state
export const AddStakeholderState = atom<StakeholderList>({
	key: 'add-stakeholder-state',
	default: []
});

// Atom for Invite Administrator state
export const IsFromTable = atom<string>({
	key: 'IsFromTable',
	default: ''
});