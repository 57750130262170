import { IDataRoomFile, IDataRoomRootFolder } from '../types';

import { ChangeEvent, useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { v4 } from 'uuid';
import axios from 'axios';
import { useNotification } from '../../../../@storybook';

import { DocType, FileTypes } from '../../constants';
import { DataRoomRootFolderState } from '../states';
import { useNetwork } from '../../../../hooks';
import { API_URL } from '../../../../constants/api';
import {
	base64ToBinary,
	convertFileToBase64,
	formatBase64,
} from '../../../../utils';
import { useBreadCrumb } from './useBreadCrumb';

// Interface for storing file metadata
interface IFileMetadata {
	name: string;
	size: number;
	type: string;
	fileId: string;
	file: File;
}

// Custom hook for handling file uploads
export const useUploadFiles = () => {
	// Recoil state for managing data room root folders and breadcrumbs
	const setDataRoomRootFolders = useSetRecoilState(DataRoomRootFolderState);

	// State to track the upload process
	const [uploadingFiles, setUploadingFiles] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState<IFileMetadata[]>([]);

	// Notification hooks for success and error messages
	const { errorNotification, successNotification } = useNotification();

	// Custom network hook for API requests
	const { post: createSignedUrl } = useNetwork();
	const { patch } = useNetwork();
	const { currentFolder, updateBreadCrumb } = useBreadCrumb();

	/**
	 * Function to upload a file to GCP using a pre-signed URL.
	 * Converts the file to binary data and sends it via a PUT request.
	 */
	const uploadOnGcp = useCallback(
		async (url: string, file: File) => {
			try {
				// Convert the file to Base64
				const base64 = await convertFileToBase64(file);
				if (!base64) throw new Error('Failed to convert file to Base64.');

				// Format the Base64 string (e.g., remove metadata)
				const formattedBase64 = formatBase64(base64 as string);
				if (!formattedBase64) throw new Error('Failed to format Base64 data.');

				// Convert the formatted Base64 string to binary (Uint8Array)
				const fileBinary = base64ToBinary(formattedBase64);
				if (!fileBinary) throw new Error('Failed to convert Base64 to binary.');

				// Send a PUT request to upload the file using the pre-signed URL
				const response = await axios.put(url, fileBinary, {
					headers: {
						'Content-Type': file?.type ?? '', // MIME type of the file
					},
				});

				// Return success if the response status is 200
				if (response.status === 200) {
					return { success: true };
				}

				// Throw an error if the upload fails
				throw new Error('Failed to upload the file to GCP.');
			} catch (error) {
				// Return failure in case of an error
				console.log('Error of upload: ' + { error });
				return { success: false };
			}
		},
		[] // No dependencies required
	);

	/**
	 * Extracts the file name without the extension
	 * @param fileName - The file name to process
	 * @returns - The file name without the extension
	 */
	const getFileNameWithoutExtension = (fileName: string) => {
		if (!fileName) return null;
		const dotIndex = fileName.lastIndexOf('.');
		return dotIndex > 0 ? fileName.slice(0, dotIndex) : fileName;
	};

	/**
	 * Handles file selection from an input field.
	 * Adds the selected file to the uploaded files state if it's a valid format.
	 *
	 * @param e - The change event triggered by the file input field
	 */
	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			if (uploadingFiles) return;
			const fileList = e.target.files;
			if (fileList) {
				const files = Array.from(fileList) as File[];
				if (files && Array.isArray(files)) {
					files.forEach((file) => {
						const { name, size, type } = file;
						// Check if the file type is supported
						if (!DocType[type]) {
							errorNotification(name + ' is unsupported file format.');
							e.target.value = ''; // Reset the input field after handling the file
						} else {
							setUploadedFiles((prev) => [
								{
									name,
									size,
									type,
									fileId: v4(),
									file,
								},
								...prev,
							]);
						}
					});
				}
			}

			e.target.value = ''; // Reset the input field after handling the file
		},
		[uploadingFiles]
	);

	/**
	 * Removes a file from the uploaded files state.
	 *
	 * @param id - The ID of the file to remove
	 */
	const handleRemove = useCallback(
		(id: string) => {
			if (uploadingFiles) return;
			setUploadedFiles((prevFiles) =>
				prevFiles.filter(({ fileId }) => fileId !== id)
			);
		},
		[uploadingFiles]
	);

	/**
	 * Returns the appropriate file name based on the file extension
	 *
	 * @param name - The file name to process
	 * @returns - The formatted file name based on its extension
	 */
	const fileName = (name: string) => {
		const splitedFile = name.split('.');
		const fileType: string = splitedFile[splitedFile.length - 1]?.toUpperCase();
		if (FileTypes[fileType]) {
			return fileType + '.svg';
		}
		return 'Default.svg';
	};

	// Accepted file types for uploads (from DocType)
	const acceptFile = Object.keys(DocType)
		.map((key) => `${key}`)
		.join(', ');

	/**
	 * Updates the status of a file upload by calling the API
	 *
	 * @param id - The file ID to update
	 * @returns - The response from the API
	 */
	const updateStatus = useCallback(async (id: string) => {
		const payload = { status: 'SUCCESS' };
		const response = await patch(`${API_URL.UPLOAD_FILES}/${id}`, payload);
		return response;
	}, []);

	/**
	 * Handles successful file uploads.
	 * Updates the data room and breadcrumb folders states with the new file.
	 *
	 * @param file - The uploaded file data
	 */
	const handleUploadSuccess = useCallback(
		(file: IDataRoomFile) => {
			// Update the data room root folders state with the new file
			setDataRoomRootFolders((prev) => {
				const prevState = structuredClone(prev);
				prevState.push(file as IDataRoomFile & IDataRoomRootFolder);
				return prevState;
			});
			// Update the breadcrumb folders with the new file
			updateBreadCrumb(file, 'upload');
		},
		[updateBreadCrumb]
	);

	/**
	 * Handles the file upload process.
	 * This function is responsible for preparing the payload,
	 * fetching signed URLs, and uploading files concurrently.
	 *
	 * @param handleModalClose - Function to close the upload modal
	 */
	const handleUploadFiles = useCallback(
		async (handleModalClose: () => void) => {
			try {
				setUploadingFiles(true);

				// Prepare the payload for signed URL creation
				const payload = {
					folderId: currentFolder?.id ?? '',
					files: uploadedFiles.map(({ name, type, fileId }) => ({
						name: getFileNameWithoutExtension(name) ?? name,
						type,
						fileId,
					})),
				};

				// Fetch signed URLs for the files to upload
				const resp = await createSignedUrl(API_URL.UPLOAD_FILES, payload);
				const { data , message } = resp ?? {};

				if (Array.isArray(data) && data.length) {
					const uploadFailed: string[] = [];
					// Upload the files concurrently using the signed URLs
					await Promise.all(
						data.map(async ({ signedUrl, fileId, id }) => {
							const findFile = uploadedFiles.find(
								(item) => item.fileId === fileId
							)?.file;

							if (findFile) {
								const gcpResp = await uploadOnGcp(signedUrl, findFile);
								const { success } = gcpResp ?? {};
								if (success) {
									const resp = await updateStatus(id);
									const { result } = resp?.data ?? {};
									if (result?.id && result?.relativeFilePath) {
										handleUploadSuccess(result);
									} else {
										uploadFailed.push(findFile.name)
									}
								} else {
									uploadFailed.push(findFile.name)
								}
							}
						})
					);

					// Notify success and close the modal only if no errors occurred
				if (!uploadFailed?.length) {
					successNotification('Files uploaded successfully.');
					handleModalClose();
				} else {
					errorNotification(
						`${uploadFailed.toString()} : Some files failed to upload or update. Please try again.`
					);
				}
				} else {
					errorNotification(message || 'No files to upload or invalid response.');
				}
			} catch (error) {
				errorNotification(
					(error as Error).message || 'Something went wrong during file upload.'
				);
			} finally {
				setUploadingFiles(false);
			}
		},
		[currentFolder?.id, uploadedFiles]
	);

	// Return values and methods from the custom hook for use in the component
	return {
		handleChange,
		fileName,
		handleRemove,
		uploadedFiles,
		acceptFile,
		handleUploadFiles,
		uploadingFiles,
	};
};
