import { FC, useMemo } from 'react';
import { Button, Image } from '../../../../@storybook';
import { Loader } from '../../../../@storybook/loader';

import { formatSizeUnits } from '../../../../utils';
import { gcpUrl } from '../../constants';
import { useUploadFiles } from '../../stores';

import './upload-files.scss';

interface IUploadFiles {
	handleModalClose: () => void;
}

/**
 * UploadFiles Component
 * Handles file uploads with drag-and-drop and browse functionality.
 * Displays the uploaded files with their details and allows removal of files.
 */
export const UploadFiles: FC<IUploadFiles> = ({ handleModalClose }) => {
	// Destructure necessary methods and state from the custom hook
	const {
		uploadedFiles,
		handleChange,
		handleRemove,
		fileName,
		acceptFile,
		handleUploadFiles,
		uploadingFiles,
	} = useUploadFiles();

	// Memoize the uploaded files mapping to ensure it is not recalculated unnecessarily
	const mapUploadedFiles = useMemo(
		() =>
			uploadedFiles.map(({ name, size, fileId }) => (
				<div key={fileId} className="Upload__Files--uploaded_item">
					{/* File Icon */}
					<div className="Upload__Files--file_icon">
						<Image
							fileName={fileName(name)}
							width="20px"
							height="24px"
							gcpUrl={gcpUrl}
						/>
					</div>

					{/* File Details */}
					<div className="Upload__Files--file_details">
						<div className="Upload__Files--file_name_wrapper">
							<div className="Upload__Files--file_details_name">{name}</div>
							<i className="ri-circle-fill" />
							<span className="Upload__Files--file_details_size">
								{formatSizeUnits(size)} {/* Format and display file size */}
							</span>
						</div>
					</div>

					{/* Remove File Action */}
					<div
						className={`Upload__Files--file-action ${uploadingFiles && 'button--disabled'}`}
						onClick={() => handleRemove(fileId)} // Handle file removal
						role="button"
						tabIndex={0}
					>
						<i className="ri-delete-bin-line" />
					</div>
				</div>
			)),
		[uploadedFiles , uploadingFiles] // Recalculate when uploadedFiles state changes
	);

	// Render the component UI
	return (
		<div className="Upload__Files">
			{/* File Upload Input with Drag-and-Drop */}
			<label
				htmlFor="browse-file"
				onDragOver={(e) => {
					e.preventDefault(); // Prevent default to allow drop
					e.stopPropagation(); // Prevent event bubbling
				}}
				onDrop={(e) => {
					e.preventDefault(); // Prevent default behavior
					e.stopPropagation(); // Prevent event bubbling
					const files = e.dataTransfer.files; // Get dropped files
					handleChange({
						target: { files },
					} as React.ChangeEvent<HTMLInputElement>); // Pass files to your handler
				}}
			>
				<div className="Upload__Files--browse">
					<div className="Upload__Files--browse_title">Files</div>
					<div className="Upload__Files--browse_file">
						<i className="ri-upload-line" />
						<div className="Upload__Files--browse_text">
							Drag & drop files here or{' '}
							<span className="Upload__Files--browse_span">browse files</span>
						</div>
						<div className="Upload__Files--browse_disc">
							Supported file types: jpg, jpeg, pdf, svg, png, doc, docx, csv,
							xls, xlsx, and webp.
						</div>
					</div>
				</div>
				<input
					type="file"
					id="browse-file"
					hidden // Hide the file input
					onChange={handleChange} // Trigger file change handler when files are selected
					accept={acceptFile} // Restrict accepted file types
					disabled={uploadingFiles}
					multiple
				/>
			</label>

			{/* Display Uploaded Files List */}
			<div className="Upload__Files--uploaded">
				{uploadedFiles?.length > 0 && (
					<>
						{/* Title of uploaded files */}
						<div className="Upload__Files--uploaded_title">
							Files
							<span className="Upload__Files--file_count">
								{uploadedFiles.length}{' '}
								{uploadedFiles.length > 1 ? 'Files' : 'File'}
							</span>
						</div>

						{/* Display the list of uploaded files */}
						<div className="Upload__Files--uploaded_list">
							{mapUploadedFiles} {/* Render the mapped files */}
						</div>
					</>
				)}
			</div>

			{/* Upload Button */}
			<div className="Upload__Files--btn">
				<Button
					label={uploadingFiles ? <Loader dimension={26} /> : 'Upload'} // Show loader if uploading
					handleClick={() => handleUploadFiles(handleModalClose)} // Trigger file upload
					type="button__filled button__filled--primary button__large button_block"
					disabled={!uploadedFiles?.length || uploadingFiles} // Disable if no files or uploading
				/>
			</div>
		</div>
	);
};
