export const convertFileToBase64 = (file: any) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = reject;
	});
};

export const isBase64 = (str: string) => {
	const base64Pattern = /^(data:([a-zA-Z0-9]+\/[a-zA-Z0-9\-\+]+);base64,)([A-Za-z0-9+/=\r\n]+)$/; return base64Pattern.test(str);
  };

// Function to remove the metadata prefix from a Base64 string
export const formatBase64 = (base64String: string): string => {
	// Replace the "data:<type>;base64," prefix with an empty string
	return base64String.replace(/^data:[^;]+;base64,/, '');
  };
  
  // Function to convert a Base64 string to a binary Uint8Array
  export const base64ToBinary = (base64String: string): Uint8Array => {
	// Decode the Base64 string into a raw binary string (ASCII characters)
	const binaryString = atob(base64String);
  
	// Convert the binary string to a Uint8Array
	// Each character in the string represents one byte, so map each character
	// to its corresponding ASCII value (using charCodeAt).
	return Uint8Array.from(binaryString, char => char.charCodeAt(0));
  };
  