import { IDataRoomFile, IDataRoomRootFolder } from '../types';

import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Image } from '../../../../@storybook';

import { AddFolder, DataRoomModalHeader, UploadFiles } from '../../components';
import { DataRoomRootFolderState } from '../states';
import { FileTypes, gcpUrl } from '../../constants';
import { useNetwork } from '../../../../hooks';
import { API_URL } from '../../../../constants/api';
import { selectedCompanyState } from '../../../header';
import { useBreadCrumb } from './useBreadCrumb';

export const useDataRoom = () => {
	// Recoil state for managing the root folders of the data room
	const [dataRoomRootFolders, setDataRoomRootFolders] = useRecoilState(
		DataRoomRootFolderState
	);

	// Recoil state to get the selected company details
	const selectedCompanyDetails = useRecoilValue(selectedCompanyState);

	// State to manage which modal (add/upload) is currently open
	const [openModal, setOpenModal] = useState<'add' | 'upload' | ''>('');

	// Network hook to perform GET requests
	const { get, loading: loadingFolders } = useNetwork();

	const { pushFolder, clearBreadCrumb } = useBreadCrumb();

	// Helper function to get the file icon based on the file extension
	const getFileIcon = useCallback((name: string): string => {
		// Extract the file extension
		const fileType = name.split('.').pop()?.toUpperCase();
		// Return the icon associated with the file type if available, otherwise return default icon
		return fileType && FileTypes[fileType] ? `${fileType}.svg` : 'Default.svg';
	}, []);

	// Function to recursively retrieve all files in a folder and its subfolders
	const getAllFilesInFolder = useCallback(
		(folder: IDataRoomRootFolder): IDataRoomFile[] => {
			if (!folder) {
				// If the folder is not defined, return an empty array
				return [];
			}

			// Filter files with status "SUCCESS" from the current folder
			const currentFiles = (
				Array.isArray(folder.files) ? folder.files : []
			).filter((file) => file.status === 'SUCCESS');

			// Recursively retrieve files from subfolders
			const subfolderFiles = (folder.subfolders || []).flatMap(
				getAllFilesInFolder
			);

			// Return combined files from current folder and subfolders
			return [...currentFiles, ...subfolderFiles];
		},
		[]
	);

	const folderData = useMemo(() => {
		return (
			dataRoomRootFolders
				?.map((folderItem) => {
					if ('file' in folderItem) return folderItem; // If it's a file, return as is

					const folder = folderItem as IDataRoomRootFolder;

					// Combine and sort files and subfolders by createdDate
					const combined = [
						...(folder.files || []),
						...(folder.subfolders || []),
					].sort(
						(a, b) =>
							new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
					);

					// Return the folder with sorted combineData
					return {
						...folder,
						combineData: folder?.combineData || combined,
					};
				})
				// Sort folderData by createdAt
				?.sort(
					(a, b) =>
						new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
				) ?? []
		);
	}, [dataRoomRootFolders]);

	// Function to render a single circle representing a file or count
	const renderCircle = useCallback(
		({
			file,
			zIndex,
			count,
		}: {
			file?: string;
			zIndex?: number;
			count?: string | number;
		}) => (
			<div style={{ zIndex }} className="Data__Room--item">
				{count ?? (
					<Image
						fileName={getFileIcon(file || '')}
						width="20px"
						height="24px"
						gcpUrl={gcpUrl}
					/>
				)}
			</div>
		),
		[getFileIcon]
	);

	// Function to render a group of circles for the files in a folder
	const renderCircles = useCallback(
		(folder: IDataRoomRootFolder) => {
			// Retrieve all files in the folder (including subfolders)
			const files = getAllFilesInFolder(folder);
			if (!files.length) return '--'; // Return placeholder if no files

			// Limit the display to the first 3 files
			const visibleFiles = files.slice(0, 3);
			const circles = visibleFiles.map(({ file }, index) =>
				renderCircle({ file, zIndex: 3 - index })
			);

			// Add a count of additional files if more than 3 files exist
			const additionalCount = files.length - visibleFiles.length;
			if (files.length > 3) {
				const countDisplay = files.length > 102 ? '99+' : `+${additionalCount}`;
				circles.push(renderCircle({ count: countDisplay }));
			}

			return circles;
		},
		[getAllFilesInFolder, renderCircle]
	);

	// Opens the modal based on the provided type ('add' or 'upload')
	const handleModalOpen = useCallback((type: 'add' | 'upload') => {
		setOpenModal(type);
	}, []);

	// Closes the currently open modal
	const handleModalClose = useCallback(() => {
		setOpenModal('');
	}, []);

	// Memoized modal properties based on the current modal type
	const modalProps = useMemo(() => {
		const commonProps = {
			showHeader: true,
			isOpen: !!openModal, // Modal is open if a type is set
			modalName: 'DataRoom Modal',
			className: 'Data__Room--modal',
			closeModal: handleModalClose,
			showCloseBtn: true,
			isStopOutsideClick: false,
		};

		if (openModal === 'add') {
			return {
				...commonProps,
				title: (
					<DataRoomModalHeader
						title="Add Folder"
						icon="ri-upload-cloud-2-fill"
					/>
				),
				children: <AddFolder handleModalClose={handleModalClose} />,
			};
		}

		if (openModal === 'upload') {
			return {
				...commonProps,
				title: (
					<DataRoomModalHeader
						title="Upload Files"
						icon="ri-upload-cloud-2-fill"
					/>
				),
				children: <UploadFiles handleModalClose={handleModalClose} />,
			};
		}

		// Default modal properties when no modal is open
		return { ...commonProps, isOpen: false, children: null };
	}, [openModal]);

	// Handles the click action on a folder row (folder or file)
	const handleClickOnRow = useCallback(
		(value: {
			combineData?: IDataRoomRootFolder[];
			relativeFilePath?: string;
			id?: string;
			itemData: IDataRoomRootFolder;
		}) => {
			const { combineData, relativeFilePath, itemData } = value || {};

			if (relativeFilePath) {
				// If it's a file, open it in a new tab
				window.open(relativeFilePath, '_blank', 'noopener,noreferrer');
			} else if (combineData) {
				// Otherwise, update breadcrumb and folder data
				pushFolder(itemData);
				setDataRoomRootFolders(combineData);
			}
		},
		[]
	);

	// Function to fetch data room folder details from the API
	const getDataRoom = useCallback(async () => {
		// Clear previous data
		setDataRoomRootFolders([]);
		clearBreadCrumb();

		// Make a GET request to fetch folder data for the selected company
		const response = await get(
			`${API_URL.FOLDER}?companyId=${selectedCompanyDetails?.id}`
		);
		const { data } = response ?? {};

		// Set the fetched data as the root folders
		setDataRoomRootFolders(data ?? []);
		const firstFolder = {
			name: 'Data room',
			combineData: data ?? [],
		};
		pushFolder(firstFolder as IDataRoomRootFolder);
	}, [selectedCompanyDetails?.id]);

	// Return all hooks, functions, and values used in the component
	return {
		folderData, // Processed folder data for rendering
		renderCircles, // Function to render circles for a folder
		handleModalOpen, // Function to open modals
		modalProps, // Properties for the modal
		gcpUrl, // Constant URL for GCP storage
		getFileIcon, // Function to get the file icon
		handleClickOnRow, // Function to handle row clicks
		getDataRoom, // Function to fetch folder data
		companyId: selectedCompanyDetails?.id, // Selected company ID
		handleModalClose, // Function to close the modal
		loadingFolders,
	};
};
