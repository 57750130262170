export const isValidEmail = (email: string) =>
	/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const isZipCodeValid = (zipCode: string): boolean => {
	// Regular expression to match alphanumeric ZIP codes with length 4-10
	const zipRegex = /^[A-Za-z0-9\- ]{4,10}$/;
	return zipRegex.test(zipCode);
};

export const containsDigit = (value: string): boolean => {
	return /\d/.test(value);
};

export const isValidURL = (url: string): boolean => {
	const urlPattern =
		/^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
	return urlPattern.test(url);
};

export const isAlphanumeric = (value: string): boolean => {
	return /^[a-zA-Z0-9\s]*$/.test(value); // Matches alphanumeric characters and allows an empty string
};

export const isValidZip = (zipCode: string): boolean => {
	// Regular expression to match alphanumeric ZIP codes with hyphen and space
	const zipRegex = /^[A-Za-z0-9\- ]*$/; // Allows alphanumeric, hyphen, and space
	return zipRegex.test(zipCode);
};

export const isAlphaWithSpaces = (value: string): boolean => {
	const regex = /^[a-zA-Z\s]*$/;
	return regex.test(value);
  };