import { Fragment, useCallback } from 'react';
import { Input } from '../../../../@storybook';
import { TextArea } from '../../../../@storybook/text-area';
import { ReactDropdown } from '../../../../@storybook/dropdown';
import { CountryMobileNumber } from '../../../../@storybook/input/country-mobile-number';
import { AutoFillLocation } from '../../../../@storybook/auto-fil-input-location';

import './form-section.scss';

export const FormSection = ({
	title = '',
	subtitle = '',
	body,
	handleChange,
	errors = {},
	formData = {},
	handleChangeSelect,
	getCustomOptions,
	handleSelectCountryCode,
	handleCountryChange,
	defaultCountryCode,
	defaultNumber,
	handleValidation,
	addressAutoFillProps,
	minDate='',
	isInputDisabled = false,
	
}: any) => {
	const renderBodyDetails = useCallback(
		(field: any) => {
			const {
				label,
				placeholder,
				name,
				type,
				options = [],
				isMulti = false,
				isRequired = false,
				description = '',
				isDisabled = false,
			} = field ?? {};
			if (type === 'auto-fill') {
				const { handleOnCompleteAutoComplete, handleClear } =
					addressAutoFillProps ?? {};
				return (
					<Fragment key={name}>
						<AutoFillLocation
							isRequired={isRequired}
							isLocate={false}
							placeHolder={placeholder}
							onSuccess={handleOnCompleteAutoComplete}
							onClear={handleClear}
							handleOnChange={handleChange}
							name={name}
							value={formData[name] || ''}
							isManualSearch={false}
							errorMessage={errors?.[name]} // Pass error message
							disabled={isInputDisabled}
						/>
					</Fragment>
				);
			}
			if (type === 'textarea') {
				return (
					<TextArea
						label={label}
						placeholder={placeholder}
						handleChange={handleChange}
						inputName={name}
						value={formData[name] || ''}
						isError={!!errors[name]}
						errorMessage={errors[name]}
						disable={isInputDisabled}
					/>
				);
			} else if (type === 'dropdown' || type === 'multi-select') {
				return (
					<>
						<ReactDropdown
							name={name}
							isRequired={isRequired}
							label={label}
							options={options || []}
							isCustomComponent={false}
							placeholder={
								name === 'permissions' ? (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<i
											className="ri-search-line"
											style={{ marginRight: '8px', color: '#9CA3AF' }}
										/>
										{placeholder}
									</div>
								) : (
									placeholder
								)
							}
							handleChangeSelect={(selectedOption: any) =>
								handleChangeSelect(selectedOption, name)
							}
							getCustomOptions={getCustomOptions}
							isMulti={isMulti}
							errorMessage={errors[name]}
							value={formData[name]}
							Isdisable={isDisabled}
							isSearchable
						/>
					</>
				);
			} else if (type === 'phoneNumber') {
				return (
					<CountryMobileNumber
						label={label}
						handleChange={handleCountryChange}
						handleChangeCountry={handleSelectCountryCode}
						defaultCountryCode={defaultCountryCode}
						defaultNumber={defaultNumber}
						inputName={name}
						inputType={type}
						placeholder={placeholder}
						handleValidation={handleValidation}
						disabled={isInputDisabled}
						isRequired
					/>
				);
			} else {
				return (
					<Input
						label={label}
						placeholder={placeholder}
						handleChange={handleChange}
						inputType={type}
						isRequired={isRequired}
						inputName={name}
						value={formData[name] || ''}
						isError={!!errors?.[name]} // Check for error
						errorMessage={errors?.[name]} // Pass error message
						description={description}
						minDate={minDate}
						disabled={isInputDisabled}
					/>
				);
			}
		},
		[errors, formData, handleChangeSelect, getCustomOptions, handleChange,isInputDisabled]
	);
	return (
		<div className="form-section">
			<div className="form-section__title">{title}</div>
			{subtitle && <p className="form-section__subtitle">{subtitle}</p>}
			<div className="form-section__body">
				{body?.map((field: any, index: any) => {
					if (field.isHidden) return null;
					return (
						<div
							key={index}
							className="form-section__field"
							style={{
								width:
									field.width !== 100
										? `${field.width - Number(field.width / 100)}%`
										: `${field.width}%`,
							}}
						>
							{renderBodyDetails(field)}
						</div>
					);
				})}
			</div>
		</div>
	);
};
