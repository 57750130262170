import { IDataRoomFile, IDataRoomRootFolder } from '../types';

import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { BreadCrumbFolderState } from '../states';

export const useBreadCrumb = () => {
	// Recoil state to manage the current breadcrumb folder hierarchy
	const [breadCrumbFolders, setBreadCrumbFolders] = useRecoilState(
		BreadCrumbFolderState
	);

	const pushFolder = useCallback((folder: IDataRoomRootFolder) => {
		setBreadCrumbFolders((prev) => [...prev, folder]);
	}, []);

	const sliceBreadCrumb = useCallback(
		(index: number) => setBreadCrumbFolders((pre) => pre.slice(0, index + 1)),
		[]
	);

	const clearBreadCrumb = useCallback(() => setBreadCrumbFolders([]), []);

	const breadcrumbLength = useMemo(
		() => breadCrumbFolders?.length ?? 0,
		[breadCrumbFolders]
	);

	const currentFolder = useMemo(
		() => breadCrumbFolders[breadcrumbLength - 1],
		[breadcrumbLength, breadCrumbFolders]
	);

	/**
	 * Updates the breadcrumb folders with a new folder or file.
	 * Ensures that the new data is added to the correct parent folder.
	 *
	 * @param prev - The previous state of the breadcrumb folders.
	 * @param newData - The new data to be added (either a folder or file).
	 * @returns - The updated breadcrumb folder state.
	 */
	const updateBreadCrumb = (
		// prev: IDataRoomRootFolder[], // The previous state of the breadcrumb folders
		newData: IDataRoomRootFolder | IDataRoomFile, // The new data to be added (either folder or file);
		type: 'add' | 'upload'
	) => {
		setBreadCrumbFolders((prev) => {
			// Clone the previous state to avoid mutations, ensuring immutability
			const prevState = structuredClone(prev ?? []);

			// Find the last index of the breadcrumb folders
			const lastIndex = prevState?.length - 1;

			// If there is at least one breadcrumb folder
			if (lastIndex >= 0) {
				// Ensure the combineData array exists for the last breadcrumb
				prevState[lastIndex].combineData =
					prevState[lastIndex].combineData || [];
				// Add the new data to the combineData array of the last breadcrumb
				prevState[lastIndex].combineData.push(newData);

				if (type === 'upload') {
					prevState[lastIndex].files = prevState[lastIndex].files || [];
					prevState[lastIndex].files.push(newData as IDataRoomFile);
				}
			}

			// Backward traversal of the breadcrumb folders to propagate changes to parent levels
			for (let n = lastIndex; n > 0; n--) {
				// Ensure the parent breadcrumb has a combineData array
				prevState[n - 1].combineData = prevState[n - 1].combineData || [];

				// Find the index of the current breadcrumb in its parent's combineData
				const childIndex = (
					prevState[n - 1].combineData as IDataRoomRootFolder[]
				).findIndex((item) => item.id === prevState[n].id);

				// If the breadcrumb already exists in the parent's combineData
				if (childIndex !== -1) {
					// Replace the old breadcrumb with the updated breadcrumb
					(prevState[n - 1].combineData as IDataRoomRootFolder[])[childIndex] =
						prevState[n];
				} else {
					// If the breadcrumb does not exist, add the updated breadcrumb to the parent's combineData
					(prevState[n - 1].combineData as IDataRoomRootFolder[]).push(
						prevState[n]
					);
				}
			}

			// Return the updated breadcrumb state
			return prevState;
		});
	};

	return {
		pushFolder,
		breadCrumbFolders,
		clearBreadCrumb,
		sliceBreadCrumb,
		updateBreadCrumb,
		currentFolder,
	};
};
