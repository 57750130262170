import { IDataRoomFile, IDataRoomRootFolder } from "./types"; // Import types for DataRoom entities
import { atom } from "recoil"; // Import Recoil's atom function for state management

// Atom for holding the state of DataRoom folders, which can be an array of IDataRoomRootFolder or IDataRoomFile objects
export const DataRoomRootFolderState = atom<IDataRoomRootFolder[] | IDataRoomFile[]>({
    key: 'data-room-folder-state-key', // Unique identifier for this atom
    default: [], // Default value is an empty array
})

// Atom for holding the breadcrumb folder state, which is an array of IDataRoomRootFolder objects
export const BreadCrumbFolderState = atom<IDataRoomRootFolder[]>({
    key: 'bread-crumb-folder-state-key', // Unique identifier for this atom
    default: [], // Default value is an empty array
})
