import { useCallback, useState } from 'react';
import { REACT_APP_API_HOST as API_HOST } from '../@storybook/envs';

export const useNetwork = ({ updateState = true } = {}) => {
	const [data, setData] = useState<any>(null);
	const [error, setError] = useState<any>(null);
	const [statusCode, setStatusCode] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [status, setStatus] = useState<any>(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const get = useCallback(
		async (url: string): Promise<any> => {
			setLoading(true);
			const apiUrl = API_HOST + url;
			try {
				const response: any = await fetch(apiUrl, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				});
				const apiPayload = await response.json();
				if (updateState) {
					setStatus(response?.ok);
					setIsLoaded(true);
					setData(apiPayload);
				}
				setStatusCode(response.status);
				return apiPayload;
			} catch (err: any) {
				if (updateState) {
					setError(err);
				}
				return null;
			} finally {
				if (updateState) {
					setLoading(false);
				}
			}
		},
		[updateState]
	);

	const post = useCallback(async (url: string, requestJSON: any) => {
		setLoading(true);
		const apiUrl = API_HOST + url;
		try {
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(requestJSON),
			});
			if (response.status === 500) {
				setError(response.type);
			}
			setStatusCode(response.status);
			setStatus(response?.ok);
			const apiData = await response.json();
			setData(apiData);
			return apiData;
		} catch (err: any) {
			setError(err);
			return null;
		} finally {
			setLoading(false);
		}
	}, []);

	const patch = useCallback(
		async (url: string, requestJSON: any) => {
		  setLoading(true);
		  const apiUrl = API_HOST + url;
		  try {
			const response = await fetch(apiUrl, {
			  method: 'PATCH',
			  headers: {
				'Content-Type': 'application/json',
			  },
			  body: JSON.stringify(requestJSON),
			});
			if (response.status === 500) {
			  setError(response.type);
			}
			setStatusCode(response.status);
			setStatus(response?.ok);
			const apiData = await response.json();
			setData(apiData);
			return apiData;
		  } catch (err: any) {
			setError(err);
			return null;
		  } finally {
			setLoading(false);
		  }
		},
		[]
	  );

	  // Add DELETE method
	const remove = useCallback(async (url: string) => {
		setLoading(true);
		const apiUrl = API_HOST + url;
		try {
			const response = await fetch(apiUrl, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			setStatusCode(response.status);
			setStatus(response?.ok);
			if (updateState) {
				setIsLoaded(true);
			}
			return response;
		} catch (err: any) {
			if (updateState) {
				setError(err);
			}
			return null;
		} finally {
			if (updateState) {
				setLoading(false);
			}
		}
	}, [updateState]);

	return {
		get,
		post,
		patch,
		data,
		status,
		error,
		loading,
		setLoading,
		isLoaded,
		setIsLoaded,
		statusCode,
		remove
	};
};
