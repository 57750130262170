export const StakeholderFormDetailsData = [
	{
		title: 'Stakeholder Information',
		fields: [
			{
				label: 'First name',
				name: 'firstName',
				type: 'text',
				placeholder: 'Enter first name',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Last name',
				name: 'lastName',
				type: 'text',
				placeholder: 'Enter last name',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Email',
				name: 'email',
				type: 'text',
				placeholder: 'Enter email',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Phone Number',
				name: 'phoneNumber',
				type: 'phoneNumber',
				placeholder: 'Enter phone number',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Institution name',
				name: 'institutionName',
				type: 'text',
				placeholder: 'Enter institution name',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Group',
				name: 'group',
				type: 'dropdown',
				placeholder: 'Select group',
				isRequired: true,
				isDisabled: false,
				width: 50,
				options: [
					{ label: 'Investor', value: 'Investor' },
					{ label: 'Ex-Employee', value: 'Ex-Employee' },
					{ label: 'Advisor', value: 'Advisor' },
					{ label: 'Employee', value: 'Employee' },
					{ label: 'Board Member', value: 'Board Member' },
					{ label: 'Ex-Consultant', value: 'Ex-Consultant' },
					{ label: 'Consultant', value: 'Consultant' },
					{ label: 'Executive', value: 'Executive' },
					{ label: 'Founder', value: 'Founder' },
				],
			},
			{
				label: 'Type',
				name: 'type',
				type: 'dropdown',
				placeholder: 'Select type',
				isRequired: true,
				isDisabled: false,
				width: 50,
				options: [
					{ label: 'Individual', value: 'Individual' },
					{ label: 'Institution', value: 'Institution' },
				],
			},
			{
				label: 'Tax ID',
				name: 'taxId',
				type: 'text',
				placeholder: 'Enter tax ID',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Street address',
				name: 'streetAddress',
				type: 'text',
				placeholder: 'Enter street address',
				isRequired: true,
				width: 50,
			},
			{
				label: 'City',
				name: 'city',
				type: 'text',
				placeholder: 'Enter city',
				isRequired: true,
				width: 50,
			},
			{
				label: 'State',
				name: 'state',
				type: 'text',
				placeholder: 'Enter state',
				isRequired: true,
				width: 50,
			},
			{
				label: 'Postal Code',
				name: 'postalCode',
				type: 'text',
				placeholder: 'Enter postal code',
				isRequired: true,
				width: 50,
			},
		],
	},
];