export const formatToCamelCase = (permission: string): string => {
	return permission
		.split('-') // Split the string by the hyphen
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
		.join(' '); // Join the words back together with a space
};

/**
 * Removes leading and trailing commas from a given string.
 *
 * @param {string} data - The input string from which commas need to be removed.
 * @returns {string} - The processed string without leading or trailing commas.
 */
export const removeComma = (data: string): string => {
	// Remove leading commas using a regular expression.
	data = data?.replace(/^,*/, '');

	// Remove trailing commas using a regular expression.
	data = data?.replace(/,*$/, '');

	// Return the modified string.
	return data;
};

export const isValidStringWithCommas = (input: string): boolean =>{
return /^[A-Za-z,]*$/.test(input);
}