/**
 * Defines the columns for the Data Room table.
 * Each column includes a key (used for data access) and a label (displayed in the UI).
 */
export const DataRoomColumns = [
	{
		key: 'name',
		label: 'Name', // The name of the data item
	},
	{
		key: 'items',
		label: 'Items', // The number of items in the data room
	},
	{
		key: 'updatedAt',
		label: 'Last modified date', // The date the data item was last modified
	},
	// Uncomment the following line to include the "Uploaded by" column if required in the future
	// { key: 'uploadedBy', label: 'Uploaded by' }
];

// Define supported file types as a record for quick lookup
export const FileTypes: Record<string, boolean> = {
	JPG: true,
	JPEG: true,
	PNG: true,
	DOC: true,
	DOCX: true,
	PDF: true,
	GIF: true,
	CSV: true,
	TXT: true,
	XSL: true,
};

// URL for accessing public assets hosted on GCP
export const gcpUrl =
	'https://storage.googleapis.com/satschel-public-assets/images/simplici-media';

// Define supported document MIME types as a record for validation
export const DocType: Record<string, boolean> = {
	'image/jpeg': true,
	'image/jpg': true,
	'image/png': true,
	'image/svg+xml': true,
	'image/webp': true,
	'application/pdf': true,
	'application/msword': true,
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': true,
	'text/csv': true,
	'application/vnd.ms-excel': true,
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': true,
};